import React from 'react'
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { Paragraph, SubTitle } from '../Shared/TypographyComponent'
import Image from '../Shared/ImageComponent'


/*
Education Component
-------------------
*/


/* Certificates Mapping */
const Certificates = ({certificateImages}) => {
    
    if ( certificateImages ) {
        return ( 
            <>
                {certificateImages.map((image) => (

                    <Stack spacing={4} key={image.id}>
                        <Image 
                            sx={{
                                height:{ xs: '22.5em', sm: '22.5em', md: '24.5em' },
                                width:{ xs: '17.5em', sm: '17.5em', md: '19.5em' }
                            }} 
                            src={image.src} 
                        />
                    </Stack>

                ))}      
            </>         
        )
    
    }
    else { //Returns Null
        return null
    }
    
}


const SchoolInformation = ({name, text, date, certificateImages}) => {

    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    return (
        <>
            {/* School Title, Date & Description */}
            <Grid container justifyContent="space-evenly">
                <Grid item xs={12} sm={4}>
                    <SubTitle>{name}</SubTitle>  
                    <Paragraph >{date}</Paragraph>  
                </Grid>

                <Grid item sm={6} sx={{pt:Mobile ? 1 : 0}}>
                    <Paragraph>{text}</Paragraph>
                </Grid>
            </Grid>

            {/* Certificates */}
            <Grid container justifyContent="space-evenly" sx={{pt:10}}>
                <Certificates certificateImages={certificateImages}/>
            </Grid>
        </>
    )    
}


/* Education Container */
function Education ({education}) {
    
    return (
        <>
            {education.map((props) => (
                <SchoolInformation  key={props.id} {...props}   />
            ))}   
        </> 
    )
}

export default Education
import React from 'react';
import { Paragraph } from '../Shared/TypographyComponent';
import { Grid,Stack,useMediaQuery } from '@mui/material';
import Thumbnail from '../Shared/ThumbnailComponent';


/*
Project Technology Stack Component
----------------------------------
*/



/* Supplemental Technology */
const SupplementalTech = ({stack}) => {

    if (stack.supplemental !== "None") {
        return (
            <>     
                <Grid container justifyContent="center" sx={{pt:7, pb:2}}>
                    <Paragraph>Supplemental Technologies</Paragraph>
                </Grid>
                
                {/*Map Thru the supplemental Array*/} 
                {stack.supplemental.map((stack) => (
                    <Stack key={stack.id}>
                        <Thumbnail micro src={stack.src} title={stack.title} alt={`${stack.title}`}/>
                    </Stack>
                ))}
            </>
        )
    }  else { //Returns Null
        return null
    }
  
}


/* Project Technology Stack */
const ProjectStack = ({stack}) => {

    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    return ( 
        <>

            <Grid container item justifyContent={"space-evenly"} xs={10} sm={12} >

                {/*Framework*/} 
                <Stack>
                    <Paragraph align={"center"}>Framework</Paragraph>
                    <Thumbnail src={stack.framework.src} title={stack.framework.title} alt={`${stack.framework.title}`}/>
                </Stack>

                {/*Styling*/} 
                <Stack>
                    <Paragraph align={"center"}>Styling</Paragraph>
                    <Thumbnail src={stack.styling.src} title={stack.styling.title} alt={`${stack.styling.title}`}/>
                </Stack>

                {/*Database*/} 
                <Stack>
                    <Paragraph align={"center"}>Database</Paragraph>
                    <Thumbnail src={stack.database.src} title={stack.database.title} alt={`${stack.database.title}`}/>
                </Stack>

                {/*Server*/} 
                <Stack sx={{pt: Mobile ? 3 : 0 }}>
                    <Paragraph align={"center"}>Server</Paragraph>
                    <Thumbnail src={stack.server.src} title={stack.server.title} alt={`${stack.server.title}`}/>
                </Stack>

                {/*Hosting*/} 
                <Stack sx={{pt: Mobile ? 3 : 0 }}>
                    <Paragraph align={"center"}>Hosting</Paragraph>
                    <Thumbnail src={stack.hosting.src} title={stack.hosting.title} alt={`${stack.hosting.title}`}/>
                </Stack>

            </Grid>


            {/*Supplemental Technology*/}
            <Grid container justifyContent="center">
                {/*Complimentary/Additional */} 
                <Grid container item xs={12} sm={7} justifyContent="space-evenly">
                    <SupplementalTech stack={stack}/>
                </Grid>

            </Grid>

        </>         
    )

}


export default ProjectStack;
import React from 'react'; 
import { styled } from '@mui/system';
import { Caption, Paragraph } from './TypographyComponent';
import { Grid, useMediaQuery } from '@mui/material';
import useHoverSpring from '../../hooks/useHoverSpring';
import {AnimatedGrid} from '../../layouts/AnimatedContainers';

/*
Thumbnail Image Component
-------------------------
*/


//Styling
const ThumbnailImage = styled('img')((props) => ({
    //background: ${({ theme }) => theme.body};
    borderRadius:'20px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
    padding:'3px',
    //borderColor: 'primary.main' //THEME WILL CONTROL IN CssBaseline
}));



const Thumbnail = ({children, src, alt, title, micro}) => {


    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    
    //Hover Spring Hook
    const [style, onMouseEnter, onMouseLeave] = useHoverSpring({
        fromOpacity: 0,
        toOpacity: 0.9,
        fromTransform: "translate3d(0px,3px,0px)",
        toTransform: "translate3d(0px,-1px,0px)",
    })


    if ( src ) {
        return (
            <>
                <AnimatedGrid container sx={{position: 'relative'}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>

                    <ThumbnailImage src={src} alt={alt}
                        sx={{ 
                            height: micro ? { xs: '4.5em', md: '4.5em' } : { xs: '5.5em', md: '7em' },
                            width: micro ? { xs: '4.55em', md: '4.5em' } : { xs: '5.5em', md: '7em' } 
                        }}>  

                        {children}
                    </ThumbnailImage>

                    {/* Accompanying Description/Footnote */}
                    { !micro ? //!micro removes hover text on micro thumbnail
                    
                        //Thumbnail Caption
                        <AnimatedGrid container justifyContent="center" 
                            sx={{
                                position: 'absolute',
                                bottom:'0%',
                                backgroundColor:'primary.main',
                                color:'text.secondary',
                                borderRadius:'0px 0px 16px 16px',
                            }}
                            style={Mobile ? null : style} //Style Null on Mobile
                            >

                            <Caption>{title}</Caption>
                        </AnimatedGrid>
                    
                    :
                        null
                
                    }

                </AnimatedGrid>
            </>
        )
    } else { //Returns Title in case No image but words needed -- Needed for Tech Stack(serverless)
        if(title) {
            return (
                <>
                   
                    <Grid item sx= {{ pt:5 }}>
                        <Paragraph>
                            {title}
                        </Paragraph>
                    </Grid>
                   
                </>
                
            )
        } else {

            return (
                <>
                    { Mobile ? 
                    
                    
                        /* Mobile Wording */            
                        <Grid item sx= {{ pt: 2 }}>
                            <Paragraph align="center"> N/A </Paragraph>
                        </Grid>
                    
                    
                    : 
                        <Grid item sx= {{ pt:5}}>
                        
                            <Paragraph>
                                Not Applicable
                            </Paragraph>

                        </Grid>

                    }
                </> 
            )

        }
    }
};


export default Thumbnail;
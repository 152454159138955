import React from 'react';
import { styled } from '@mui/system';
import { Paragraph } from '../Shared/TypographyComponent';

/*
External Custom Link Component
------------------------------
*/


//Link(Anchor) Styling
const StyledLink =  styled('a', {
    
    shouldForwardProp: (prop) => prop !== "linkColor" /*DECLARING PROP NAME */})
    
    (({ theme, linkColor }) => ({
    color: linkColor ? theme.palette.text.link /*Default prop color for Text wrapped link*/ : theme.palette.primary.main,
    '&:hover': {
        color:  theme.palette.mode === 'dark' && linkColor/* Ensure prop is passed */ ?  '#023DFD': '#17D1FA',
        textDecoration: 'underline',
    }
}));


const ExternalLink = ({href, children, target, onClick, ariaLabel, linkColor}) => {
    

    if(href === null) { //EXCLUSIVELY FOR PERSONAL PROJECT LINK
        return (
            <Paragraph sx={{pb:1}}> & &nbsp;you're here 😅 </Paragraph>
        )
        
    } else {
        return (
            <StyledLink href={href} target={target} onClick={onClick} aria-label={ariaLabel} linkColor={linkColor}>
                {children}
            </StyledLink>
        )
    }
}

export default ExternalLink;
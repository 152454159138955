import React from 'react';
import { Paragraph} from '../Shared/TypographyComponent';
import { Grid } from '@mui/material';
import Link from '../Shared/LinkComponent';
import {ForwardButton} from '../Shared/ButtonComponent';


/*
Project Call To Action Component
--------------------------------
*/



const ProjectCTA = ({item}) => {

    //Item is passed in as props due to react-spring animation on Home Page
    if(item.subdirectory) {
        return (
            
            <>
                <Grid item>
                    <Paragraph> Explore Case Study </Paragraph>
                </Grid>

                <Grid item sx={{pt:1}}>
                    <Link to={`/projects/${item.subdirectory}`} aria-label={`View The ${item.subdirectory} Project`}>
                        <ForwardButton projectColor={item.colorPalettes[0].title} />
                    </Link>
                </Grid>
            </>     

        )
    } 
    else { //PROJECTS THAT ARE BEING WORKED ON
        return(
            <Paragraph sx={{pt:2.5}}>🚧 Under Construction 🚧</Paragraph>
        )
    }

}

export default ProjectCTA;
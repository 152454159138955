import React from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Grid } from '@mui/material';
import useBounce from '../../hooks/useBounce';
import { animated } from '@react-spring/web';



/*Scroll Arrow - Call To Action - With Spring*/
const ScrollArrow = () => {

    //Bounce Hook
    const [style] = useBounce({
        y: 10,
        reverse:true
    })

    //Styled Arrow
    const StyledArrowDown = animated(KeyboardArrowDownIcon)
    return (

        <Grid container justifyContent="center" sx={{mt:-15}}>
            <StyledArrowDown sx={{ fontSize: 80}} style={style} />     
        </Grid>
  
    )
          
}

export default ScrollArrow;
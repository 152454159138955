/*
Biography Data
--------------
*/


import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
//import InstagramIcon from '@mui/icons-materialInstagram';
//import TwitterIcon from '@mui/icons-materialTwitter';
//import GetAppIcon from '@mui/icons-materialGetApp';
import DescriptionIcon from '@mui/icons-material/Description';



const Bio = [
    
    {
        header:"I am a full-stack software engineer based in Seattle 🇺🇸",
        currentRole:"Director of Engineering",
        currentOrg:"Gener8tor",
        orgSite:"https://www.gener8tor.com",
        description:"I am the subsequent result of self-taught passion, tutorials, and the “learn by doing” methodology. I design and build full-stack web applications and cross-platform mobile apps.",
        image:"/images/about/NasirAbdirahman.jpg",
        backgroundImage:"/images/about/background.jpg",
        links:[ //Contact Component
            {
                //LinkedIn
                id:0,
                title: "LinkedIn",
                icon: <LinkedInIcon style={{ fontSize: 50 }} />,
                url: "https://www.linkedin.com/in/abdul-nasir-abdirahman/",
            },
            {
                //Github
                id:1,
                title: "GitHub",
                icon: <GitHubIcon style={{ fontSize: 50 }} />,
                url: "https://github.com/NasirAbdirahman",
            },
            /*{
                //Instagram
                id:2,
                title: "Instagram",
                icon:<InstagramIcon style={{ fontSize: 50}}/>,
                url: "https://www.instagram.com/nasir.abdirahman/",
            },
            {
                //Twitter
                id:3,
                title: "Twitter",
                icon:<TwitterIcon style={{ fontSize: 50 }}/>,
                url: "https://twitter.com/NenoAbdirahman",
            },*/
            {
                //Resume on Google Drive
                id:4,
                title: "Resume",
                icon: <DescriptionIcon style={{ fontSize: 50 }}/>,
                url: "https://drive.google.com/file/d/1r3vPhTUWA0F3OZ5Xjk9sfxQIhzsDQ9kh/view?usp=share_link",
            }
        ],
        skills: [ //Areas of Expertise Component

            //LANGUAGES
            {id:0,title:"JavaScript",src:"/icons/javascript.svg"},
            {id:1,title:"Python",src:"/icons/python.svg"},
            {id:2,title:"HTML5",src:"/icons/html-5.svg"},
            {id:3,title:"CSS",src:"/icons/css-3.svg"},
            {id:4,title:"SASS",src:"/icons/sass.svg"},

            //FRONT-END
            {id:5,title:"ReactJS",src:"/icons/react.svg"},
            {id:6,title:"React Native",src:"/icons/ReactNative.png"},
            {id:7,title:"Material UI",src:"/icons/material-ui.svg"},
            {id:8,title:"TailwindCSS",src:"/icons/tailwindcss.svg"},
            {id:9,title:"Bootstrap",src:"/icons/bootstrap-logo.svg"},
            {id:10,title:"Styled Comps.",src:"/icons/StyledComponentslogo.png"},
            {id:11,title:"ReactBootstrap",src:"/icons/reactbootstrap.svg"},
            {id:13,title:"ThreeJS",src:"/icons/threeJS.png"},
            {id:14,title:"jQuery",src:"/icons/jquery.svg"},
            {id:15,title:"Redux",src:"/icons/redux.svg"},
            {id:16,title:"React Router",src:"/icons/react-router.svg"},
            {id:17,title:"React Spring",src:"/icons/react-spring.svg"},
            {id:18,title:"@use-gesture",src:"/icons/use-gesture.svg"},
            //{id:12,title:"NextJS",src:"/icons/gatsby.svg"},//WILL LEARN by Project

            //BACK-END
            {id:19,title:"Node.JS",src:"/icons/nodejs.svg"},
            {id:20,title:"Express",src:"/icons/express.svg"},
            {id:21,title:"MongoDB",src:"/icons/mongodb.svg"},
            {id:22,title:"Django",src:"/icons/django-icon.svg"},
            {id:23,title:"PostgreSQL",src:"/icons/postgresql.svg"},
            //{id:20,title:"GraphQL",src:"/icons/graphql.svg"},//WILL LEARN by Project

            //TOOLS
            {id:24,title:"NPM",src:"/icons/npm.svg"},
            {id:25,title:"Yarn",src:"/icons/yarn.svg"},
            {id:26,title:"Git",src:"/icons/git.svg"},
            {id:27,title:"Github",src:"/icons/github-icon.svg"},
            {id:28,title:"Selenium",src:"/icons/selenium.svg"},
            {id:29,title:"Playwright",src:"/icons/playwright.svg"},
            {id:30,title:"Webpack",src:"/icons/webpack.svg"},
            {id:31,title:"Babel",src:"/icons/babel.svg"},
            {id:32,title:"Jest",src:"/icons/jest.svg"}, 
            {id:33,title:"Expo SDK",src:"/icons/expo-icon.svg"},
            {id:34,title:"Postman",src:"/icons/postman-icon.svg"},
            {id:35,title:"JIRA",src:"/icons/jira.svg"},
            {id:36,title:"BitBucket",src:"/icons/bitbucket.svg"},
            {id:37,title:"Heroku",src:"/icons/heroku.svg"},
            {id:38,title:"AWS Cloud",src:"/icons/aws.svg"},
            {id:39,title:"AWS S3",src:"/icons/aws-s3.svg"},
            {id:40,title:"AWS Amplify",src:"/icons/aws-amplify.svg"},
            //{id:12,title:"GatsbyJS",src:"/icons/gatsby.svg"},//WILL LEARN by Project
            //{id:12,title:"Docker",src:"/icons/gatsby.svg"},//WILL LEARN by tutorial
            //{id:12,title:"D3",src:"/icons/gatsby.svg"},//WILL LEARN by Project
        ],
        existenceStatements:[
            {id:0, title:"I value love and positivity. I am torn between my passion for the Manchester United and Barcelona Futbol Clubs. I currently hold a personal record of 37 pullups, no time limit. I am also a proud member of the largest soccer group in the Seattle area, with over 1.5k people on the waiting list, and I hardly attend meetups. I look for any excuse to enjoy the beautiful PNW weather, especially on rainy days."}, 
            {id:1, title:"I am an amateur traveler and I have been to 4 countries and counting."},
            {id:2, title:"I dive deep into the problems that I solve and the goals that I need to achieve. I ask an absurd amount of questions, research solutions and create clear roadmaps to deliver functional code and features. I learn quickly and drive to find solutions when it is incumbent upon me to do so. I love interacting with key stakeholders, explaining technical concepts and engineering tradeoffs to a non-tech audience."},
            {id:3, title:"I am kind, compassionate, and I approach every situation with empathy. I find that a work-environment with a team-first mentality best suits me as an individual with excellent social skills and a positive demeanor. I believe in psychological safety for myself and my team members. Respecting everyone's preferences, differences and personalities is a moral obligation in any organization. I believe that a team with this concept as a central tenet can only succeed."},
            {id:4, title:"I pride myself on not being taught the same thing twice."}
        ],
        existenceImages:[//TRAVEL IMAGES
            {id:0, title:"Ijen Volcano,ID",src:"/images/about/IjenVolcano.jpg"},
            {id:1, title:"Halong Bay,VN", src:"/images/about/HalongBay.jpg"},
        ],
        education: [ //Education Component
            {
                id:0,
                name:"Nucamp Coding Bootcamp",
                text:"Studied the fundamentals of full stack software development under the mentorship of industry professionals with decades of experience. I graduated with Honors in the top 10% of all students.",
                certificateImages: [
                    {id:0, src:"/images/about/education/Bootstrap Course Certificate.png"},
                    {id:1, src:"/images/about/education/React Course Certificate.png"},
                    {id:2, src:"/images/about/education/React-Native Course Certificate.png"},
                    {id:3, src:"/images/about/education/NodeJS:Express:MongoDB Course Certificate.png"},
                    {id:4, src:"/images/about/education/HONORS STUDENT Front-End Web & Mobile Development Certificate.png"},
                    {id:5, src:"/images/about/education/Full-Stack Web & Mobile Development Certificate.png"},
                ],
                date: "Dec 2020 - May 2021",
            },
            /*{//CURRENTLY NOT PURSUING
                id:1,
                name:"Western Governor's University",
                text:"Currently pursuing a B.S. in Software Development, with an emphasis in C#",
                date: "Sep 2020 - Graduation",
            },*/
             
        ],
        references: [ //Recommendations Component
            {
                id:0,
                name: "Leo Sinani",
                position: "Developer @ Fannie Mae",
                text: "It is with much enthusiasm that I recommend Nasir for web development position.I was Nasir's instructor for Nucamp bootcamp React class. In our class, Nasir displayed a level of creativity, wit, and analytical thought that is quite rare among other students.",
                image:"/images/about/recommenders/LeoSinani.jpg",
                relationship: "Teacher at Nucamp Coding Bootcamp",
                date: '09/2021',
            },
            {
                id:1,
                name: "Flor Antara",
                position: "Software Engineer @ Twilio",
                text: "Nasir was one of my students in the NuCamp fullstack bootcamp. He's so passionate and dedicated that it was a pleasure to work with him. He always went the extra mile learning more in depth about the topic given and he even helped me clarify topics to the rest of the class. He's a go-getter and a team player. I'm sure he'll excel in this industry.",
                image:"/images/about/recommenders/FlorAntara.jpg",
                relationship: "Teacher at Nucamp Coding Bootcamp",
                date: '11/2021',
            },
            {
                id:2,
                name: "Joe Baz",
                position: "Principal of Engineering, eCommerce @ Resource Innovations(TechniArt)",
                text: "Nasir is an excellent developer and a wonderful teammate. I had the privilege of supervising Nasir while at gener8tor, and I can attest that Nasir brings a wealth of technical skills, a passion for software development, and a can-do attitude to the job. Nasir has shown a lot of attention to detail on our projects. He knows how to ask the right questions and work with multiple stakeholders. He researches his solutions, makes clear roadmaps, and graciously works with his fellow developers. Nasir brought a lot of Django/Python/backend expertise to our team and made several high-impact contributions to our workflow. I think his strongest skill is his ability to learn and perform in a fast-paced and demanding environment. I can tell that new ideas and challenges excite Nasir, and he brings a lot of enthusiasm to the team. He was a great mentor to our junior developers and a trustworthy technical counsel for our senior management. I would strongly recommend Nasir for positions that require mastery of technical skills as well as strong interpersonal skills.",
                image:"/images/about/recommenders/Baz.jpg",
                relationship: "Managed Nasir Directly",
                date: '03/2023',
            },
             
        ],
        greetings:[ //Greetings Component

            {
                id:0,
                title: "Salaam",
                language: "Arabic",
            },
            {
                id:1,
                title: "Peace",
                language: "English",
            },
            {
                id:2,
                title: "Perdamaian",
                language: "Indonesia",
            },
        ]
  
    },

]

export default Bio;
import React,{useState, useCallback} from 'react';
import { SubTitle, Paragraph } from '../Shared/TypographyComponent';
import { Grid, Box,Avatar,useMediaQuery } from '@mui/material';
import { ForwardButton, BackButton } from './ButtonComponent';
import useInterval from '../../hooks/useInterval'
import useHover from '../../hooks/useHover';
import CircularProgress from '@mui/material/CircularProgress';
import { useTransition } from '@react-spring/web';
import {AnimatedGrid} from '../../layouts/AnimatedContainers';
import ExternalLink from './ExternalLinkComponent';
/*
Recommendation Component
------------------------
*/



/* Recommender Information */
const RecommenderDescription = ({references}) => {
    
    //Hover State Initialized
    const [hover, setHover] = useHover();

    //Index State Initialized
    const [index,setIndex] = useState(0);
    
    //Avatar Progress State Initiated
    const [progress, setProgress] = useState(0);

    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    //Backward Cycling -- using Mod = optimization
    const mod = (n, m) => ( (n % m) + m) % m ;

    //Forward Button Function
    const forward = useCallback(() => {
        setIndex(index => mod(index + 1 , references.length))
        setProgress((progress) => (progress === 0))//Resets Progress bar
        },
        [setIndex, references],
    )

    //Backward Button Function
    const backward = useCallback(() => {
        setIndex(index => mod(index - 1 , references.length))
        setProgress((progress) => (progress === 0))//Resets Progress bar
        },
        [setIndex, references],
    )

    /*Interval Hook That Ticks Progress bar of Avatar
    Calls forward cycling with Hover hook that Pauses Cycling*/
    useInterval(() => {

        setProgress((progress) => (progress + 0.1));

        if(progress >= 100){
            forward();
        }

    },(setHover && !Mobile) ? null : 10);


    //Avatar & Progress Circle
    const AvatarProgress = (props) => {
        return (
            <Box position="relative" display="inline-flex" alignItems="center" justifyContent="center">
                <CircularProgress variant="determinate" value={parseInt(progress)}/* Value must be number or error */ size={130} color="inherit" sx={{color:"#17D1FA"}}/>        
                {props.content}  {/* Avatar Goes Here */}
            </Box>
        );
    }


    /* Project Container Spring Transition */
    const referencesTransitions = useTransition(references[index], {
        from: {
            opacity: 0,

        },
        enter: {
            opacity: 1,

        },
        config: { duration: 1000 },
    });



    return ( 
        <>
            {/* References Transitions */}
            {referencesTransitions((props, item) => {
                return(
                    <>
                        <AnimatedGrid container ref={hover} style={{...props}}> 
                            <Grid container item key={item.id} sm={4} justifyContent="space-evenly" alignItems="flex-end" >
                                
                                {/* Recommender Image */}
                                <Grid container justifyContent="center">
                                <AvatarProgress 
                                    content={ 
                                        <Avatar sx= {{position:'absolute',height:{ xs: 120 },width:{ xs: 120 }}} src={item.image} /*alt={references[index].name}*//>
                                    }
                                />

                                {/* <Avatar sx= {{height:{ xs: 120 },width:{ xs: 120 }}} src={references[index].image} alt={references[index].name}/>*/}

                                </Grid>

                                {/* Name, Position, Date */}
                                <Grid item sx={{pt:Mobile?3:0}}>
                                    <SubTitle align="center"> 
                                        <ExternalLink href={"https://www.linkedin.com/in/abdul-nasir-abdirahman/details/recommendations/"} target="_blank">
                                            {item.name}
                                        </ExternalLink>
                                    </SubTitle>
                                    <Paragraph align="center">{item.position}</Paragraph>
                                </Grid>

                                {/* Scroll Arrows */}
                                <Grid container justifyContent="center" sx={{pt:2}}>

                                    <Grid item>
                                        <BackButton sx={{ fontSize:35}} onClick={backward}/>
                                    </Grid>

                                    <Grid item sx={{ px:2, pt:0.5 }}>
                                        <Paragraph> {item.id + 1} / {references.length} </Paragraph>
                                    </Grid>

                                    <Grid item>
                                        <ForwardButton sx={{ fontSize:35}} onClick={forward}/>
                                    </Grid>

                                </Grid>

                            </Grid> 


                            {/* Recommendation Text */}
                            <Grid container item sm={7.5} alignItems="center">

                                <Grid item sx={{pt:Mobile?3:0}}>
                                    <SubTitle>{item.text}</SubTitle>   
                                </Grid>

                            </Grid>   
                        </AnimatedGrid> 
                    </> 
                );
            })}

        </>         
    )
    
}



/* Recommendation Container */
function Recommendations ({references}) {
  
    return (
        
        <RecommenderDescription references={references}/>
     
    )
}

export default Recommendations;
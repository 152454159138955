import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { animated } from '@react-spring/web';
import { useTheme} from '@mui/material/styles';
import useHoverSpring from '../../hooks/useHoverSpring';

/*
Button Components
-----------------
*/



//Forward Arrow Button
const ForwardButton = ({ sx, onClick, projectColor }) => {
  
  //StyledArrow
  const StyledArrowForward = animated(ArrowForwardIcon)

  //Theme Prop Passed In
  const theme = useTheme();

  //Bounce Hook
  const [style, onMouseEnter, onMouseLeave] = useHoverSpring({
    x : 7, 
    fromColor : projectColor ? projectColor : theme.palette.primary.main, //project color passed in
    toColor : projectColor ? theme.palette.primary.main : "#17D1FA"
  })


  return ( 
    <StyledArrowForward 
      style={style} 
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave} 
      onClick={onClick} 
      aria-label={"Forward Arrow"} 
      sx={{ fontSize: { xs: 40, md: 50 }, ...sx}} 
    />

  )
}




//Backward Arrow Button
const BackButton = ({ sx, onClick }) => {
  
  //Styled Arrow
  const StyledArrowBack = animated(ArrowBackIcon)

  //Theme Prop Passed In
  const theme = useTheme();
  
  //Bounce Hook
  const [style, onMouseEnter, onMouseLeave] = useHoverSpring({
    x : -7, 
    fromColor : theme.palette.primary.main, 
    toColor : "#17D1FA"
  })


  return ( 

    <StyledArrowBack 
      style={style} 
      onMouseEnter={onMouseEnter} 
      onMouseLeave={onMouseLeave} 
      onClick={onClick} 
      aria-label={"Back Arrow"} 
      sx={{ fontSize: { xs: 40, md: 50 }, ...sx }} 
    />

  )
}



export { ForwardButton, BackButton  };
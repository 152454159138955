import {useEffect,useRef } from 'react';


/*
Interval React Hook
-------------------
*/


function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
}


/* 
USAGE EXAMPLE

useInterval(() => {
    // custom logic here
    }, 8000); 
*/


export default useInterval;
import React from 'react';
import { Grid } from '@mui/material';
import { Caption } from '../Shared/TypographyComponent';
import ExternalLink from '../Shared/ExternalLinkComponent';


/*
Extra Features Component
------------------------
*/


/* Links, Videos, Publications Mapping */
const ExtraFeatures = ({project}) => {

    if ( project.extraLinks ) {
        return ( 
            <>
                {project.extraLinks.map((link) => (
                   
                    <Grid key={link.id} container justifyContent={"center"}>
                        <ExternalLink href={link.link} target="_blank" linkColor>
                            <Caption sx={{pb:5}}>{link.text}</Caption>
                        </ExternalLink>
                    </Grid>
                  
                ))}
            </>         
        )
    }
    else { //If no extra links
        return null
    }
    
}

export default ExtraFeatures;
import React,{ useState, useEffect, useMemo } from 'react';
import {HelmetProvider} from 'react-helmet-async';
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import './App.css'; //Needed for Home Page Scroll Hidden
import { CssBaseline } from '@mui/material';
import Home from "./pages/Home";
import About from "./pages/About";
import Projects from "./pages/Projects";
import Navigation from './components/Shared/NavigationComponent';
//import NotFound from "./pages/NotFound"
import ScrollToTop from './layouts/ScrollToTop';

//Theme Files
import {DarkModeContext} from './styles/darkMode'
import { ThemeProvider, createTheme} from '@mui/material/styles';
import typography from './styles/typography';
import palettes from './styles/palettes'
import useMediaQuery from '@mui/material/useMediaQuery';



function App() {

  //Dark Mode Client Query
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

  //State Initialized
  const [darkness, setDarkness] = useState(prefersDarkMode);

  //Color theme preference executes on mount
  useEffect(() => {    
    //check for localStorage, add as browser preference if missing
    if(!localStorage.getItem("mode")){
      if(window.matchMedia("(prefers-color-scheme: dark)").matches){
        localStorage.setItem("mode", 'dark');
        //setDarkness(prefersDarkMode)
      } //else { //NO POINT IN SETTING MODE TO LIGHT AND setDarkness(darkness)
        //localStorage.setItem("mode", 'light');
        //setDarkness(darkness)}
    }

    //set interface to match localStorage
    if(localStorage.getItem('mode') === 'dark'){
      setDarkness(prefersDarkMode) 
      //localStorage.setItem("mode", 'dark'  )  
    } /*else {
      setDarkness(darkness) 
      localStorage.setItem("mode", 'light'  )
    }*/
  
  }, [darkness,prefersDarkMode]);



  //Dark Mode Toggler memoized
  const darkMode = useMemo(
    () => ({
      toggleDarkMode: () => {

        //Sets LocalStorage only if prefersDarkMode is system setting
       if(prefersDarkMode){
         localStorage.setItem("mode", darkness ? 'light' : 'dark' )  
        } 
      
       setDarkness((prevMode) => prevMode === 'light' ? prefersDarkMode : !darkness);

      },
      
    }),
    [darkness,prefersDarkMode],
  );


  

  //Theme Creation; imported typography & palettes
  const theme = useMemo(
    () =>
      createTheme({
        typography:typography,
        palette: {
          ...(darkness ? palettes.dark : palettes.light)
        },
      }),
    [darkness],
  );

    
  return (
    <> 
      <DarkModeContext.Provider value={darkMode}>

        <ThemeProvider theme={theme} >

          <CssBaseline/> {/*CONTROLS ROOT OF HTML DOCUMENT--USES "DEFAULT" THEME & 
          CONTROLS THE BORDER/BG COLOR(I DONT NEED TO ADD IT IN SX IN COMP.*/}

          <HelmetProvider>

            <BrowserRouter>

              <ScrollToTop>

                <Navigation/>
               
                <Routes>
                  
                  <Route path="/" element={<Home />}/>
                  <Route path="/projects/:subdirectory" element={<Projects/>} />{/*/projects added b/c error URLS not caught on search. Needed a base*/}
                  <Route path="/about" element={<About/>}/>
                  {/*Not Found Path until NotFound Page rendered */}
                  <Route path="*" element={<Navigate replace to="/" />}/>

                </Routes>

              </ScrollToTop>

            </BrowserRouter>

          </HelmetProvider> 

        </ThemeProvider>

      </DarkModeContext.Provider>
    </>
  )
}

export default App;
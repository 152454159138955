import React, {useState } from "react";
import {useTransition, useSpring,useChain,useSpringRef} from "@react-spring/web"
import {Header, SubHeader, SubTitle,Caption} from '../components/Shared/TypographyComponent';
import Image from '../components/Shared/ImageComponent';
import HelmetTag from '../components/Shared/HelmetComponent';
import PROJECTS from '../data/projects';
import { Grid, Box, useMediaQuery, Avatar} from '@mui/material';
import {useWheel} from '@use-gesture/react';
import { Lethargy } from 'lethargy';
import CustomContainer from '../layouts/container';
import ProjectCTA from '../components/Home/ProjectCTAComponent';
import {AnimatedDiv, AnimatedGrid} from  '../layouts/AnimatedContainers'
import Divider from '../components/Shared/DividerComponent'

/*
Home Page W/ Projects Showcase
-------------------------------
*/



function Home() {


  //State Initialized
  const [index, setIndex] = useState(0)
  
  //Checks LocalStorage for user visitor
  let showAnimation = localStorage.getItem("showLaunchAnimation");

  //Mobile MediaQuery
  const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

  //UseWheel Scrolling with Lethargy
  const lethargy = new Lethargy()
  const clamp = (value, min, max) => Math.max(Math.min(max, value), min)

  
  /*Scroll Binding*/
  const bind = useWheel(({ event, last, memo: wait = false }) => {
    if (!last) {
      const s = lethargy.check(event)
      if (s) {
        if (!wait) {
          setIndex((i) => clamp(i - s, 0, PROJECTS.length - 1))
          return true
        }
      } else return false
    } else {
      return false
    }
  })

  
  /* Launch Transition */
  const [show, setShow] = useState(true)
  const launchTransApi = useSpringRef()
  const launchTransitions = useTransition(show, {
    ref: launchTransApi,
    from: {
      opacity: 0,
    },
    enter: {
      opacity:  1,
    },
    leave: {
      opacity: 1,
    },
    onRest: () => setShow(false),
    config: { duration: 2200 },
    
  })

  /*Launch Transition - Emoji Spring */
  const emojiSpringApi = useSpringRef()
  const emojiStyles = useSpring({
    ref: emojiSpringApi,
    from: { transform: "rotate(0deg) translate3d(0px,0px,0px)" },
    to: { transform: "rotate(5deg) translate3d(7px,0px,7px)" },
    config: { duration: 150 },
  })

  /*Launch Transition - Name Spring*/
  const nameSpringApi = useSpringRef()
  const nameStyles = useSpring({
    ref: nameSpringApi,
    from: { opacity: 0 },
    to: { opacity:1 },
    config: { duration: 1500 },
  })

  /*Launch Transition - Welcome Text Spring*/
  const welcomeSpringApi = useSpringRef()
  const welcomeStyles = useSpring({
    ref: welcomeSpringApi,
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1500 },

  })


  /* Project Container Spring Transition */
  const projectTransApi = useSpringRef()
  const projectTransitions = useTransition(PROJECTS, {
    ref: projectTransApi,
    from: {
      opacity: /*showAnimation ? 1 :*/ 0,
      scale: /*showAnimation ? 1 : */ 0,
      transform: /*showAnimation ? "rotate(0deg) translate3d(0px,0px,0px)" : */ "rotate(0deg) translate3d(0px,-1000px,0px)"
    },
    enter: {
      opacity: 1,
      scale:1,
      transform: "rotate(0deg) translate3d(0px,0px,0px)",
    },
    config: { duration: showAnimation ? 975 : 1000 },
  });


  /*Project Header & Text Spring */
  const projectSpringApi = useSpringRef()
  const projectSpringStyles = useSpring({
    ref: projectSpringApi,
    from: { opacity: /*showAnimation ? 1 : */0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
    //Landing Animation localStorage STATE set HERE
    onRest:() => localStorage.setItem("showLaunchAnimation", true),
  })


  /* Springs Chained Together */
  useChain(
    [
      launchTransApi,
      emojiSpringApi, 
      nameSpringApi,
      welcomeSpringApi,
      projectTransApi,
      projectSpringApi
    ], [//Time to wait before Spring executions
      0,
      1.5,
      1,
      2.2,
      showAnimation ? 0 : 4.4, //Projects appear immediately if user already visited site
      showAnimation ? 0.75 : 5.3 //Projects appear immediately if user already visited site
    ]
  )



  return (
    <>

      {/* Home Helmet Tag */}
      <HelmetTag/>

      <CustomContainer sx={{ pt:{xs:5, sm:10}, overflowY:Mobile ? "auto" : 'hidden'}} props={{...bind()}} > 

        {/*Launch Transition - Only appears if user has never visited site*/}
        {(showAnimation) ? null : 
      
          launchTransitions((styles, item) => { 
            return(
              item && 
              <>
                <AnimatedGrid container justifyContent="center" sx={{pt:Mobile ? 10 : 15}} style={styles}>
  
                  <AnimatedGrid  item style={emojiStyles}>
                    <Avatar sx={{ width: 300, height: 300 }} src={"/favicon.png"} alt={"My Emoji"} />
                  </AnimatedGrid>
                  
                  <AnimatedGrid container item  md={5} justifyContent="center" sx={{pt:10}} style={nameStyles} >
                    <Grid item>
                      <SubHeader align="center">Nasir Abdirahman</SubHeader> 
                    </Grid>
  
                    <Grid container item justifyContent="center">
                      <Divider width={Mobile ? "10em" : "17em"} height="0.15em"/>
                    </Grid>
  
                    <AnimatedGrid item sx={{pb:Mobile ? null : 10, pt:Mobile ? 2 : 2.5}} style={welcomeStyles} >
                      <SubTitle align="center">Welcome to my site</SubTitle> 
                      <SubTitle>Check out some things I've built</SubTitle>  
                    </AnimatedGrid>
                    
                  </AnimatedGrid>
                    
                </AnimatedGrid>
              </>
            )
          })
        }
        
        {/* Project Transitions */}
        {projectTransitions((props, item) => {
          return(
            <>
            
              <AnimatedDiv style={{...props}}>

                <Grid  container key={item.id} justifyContent="space-evenly" alignItems="center" 
                  sx={{
                    
                    minHeight: {xs:'52.5vh', md:'70vh'},//Height of the Block
                    //minWidth: '85vw',
                    margin: {xs:'15vh 0', md:'0'}, //Space Between Each Block
                    transform: {xs:`translateY(${-index * 64.5}vh)`, sm:`translateY(${-index * 67.5}vh)`},
                    transition: `transform 350ms ease-in-out`//350ms
                  }}>

                  
                  {/* Header, Description and CTA Animated*/}
                  <AnimatedGrid container item xs={12} md={5} style={projectSpringStyles}>

                    {/* Project Header */}
                    <Grid container item justifyContent={{xs:"center", md:"space-between"}} >       
                      <Header sx={{color:`${item.colorPalettes[0].title}`}}>
                        {item.header}
                      </Header>
                    </Grid> 

                    {/* Project Summary */}
                    <Grid container item justifyContent="center" /*order={{ xs: 0, sm: 0 }}*/>
                      <SubTitle align="center">{item.summary}</SubTitle> 
                    </Grid>
                
                  
                    {/* Project CTA */}
                    <Grid container justifyContent="center" alignItems="center" >
                      <ProjectCTA item={item}/>
                    </Grid>

                  </AnimatedGrid>
                
                  {/* Project Image */}
                  <Grid container item xs={12} md={7} justifyContent="center">
                    <Image main src={item.image} alt={"Project Capstone"} />
                  </Grid>
                      
                </Grid>

              </AnimatedDiv>
            
            </>
          );
        })}

      
      </CustomContainer>
      
      {/*Copyright Slogan */}
      {Mobile ? null : 
      <Box position={'absolute'} bottom={0} pl={1}>
        <Caption>©️ Nasir Abdirahman - 2022 All Rights Reserved </Caption>                      
      </Box>}

    </>
  )

 
}


export default Home;

import React from 'react';
import { Grid, Stack,useMediaQuery } from '@mui/material';
import { useParams } from 'react-router-dom';
//Components
import CustomContainer from '../layouts/container'
import ProgressBar from '../components/Projects/ScrollProgressBar'
import {Header,SubHeader,Title, SubTitle, Paragraph} from '../components/Shared/TypographyComponent';
import Divider from '../components/Shared/DividerComponent';
import ProjectStack from '../components/Projects/ProjectStackComponent'
import ProjectPalette from '../components/Projects/ProjectPaletteComponent';
import BulletList from '../components/Projects/BulletList';
import ScrollArrow from '../components/Shared/ScrollArrow';
import { ForwardButton } from '../components/Shared/ButtonComponent';
import ProjectTypography from '../components/Projects/ProjectTypographyComponent';
import Awards from '../components/Projects/AwardsComponent';
import HelmetTag from '../components/Shared/HelmetComponent';
import ExternalLink from '../components/Shared/ExternalLinkComponent';
import Image from '../components/Shared/ImageComponent';
import ExtraFeatures from '../components/Projects/ExtraFeatures'
import ProjectMockups from '../components/Projects/ProjectMockUps'
//Data
import PROJECTS from '../data/projects';


/*
Projects Page with Project Details Component
--------------------------------------------
*/



/*Displays The Project's Information*/
function ProjectDescription({project}) {

  //Mobile MediaQuery
  const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

  return (
    
    <>

        {/* Project Helmet Tag */}
        <HelmetTag title={`Nasir Abdirahman - ${project.header}`} 
            description={`Nasir Abdirahman's - ${project.header} Project`}
            href={`https://www.nasirabdirahman.com/projects/${project.subdirectory}`}
        /> 


        {/*Project Page Progress bar */}
        <Grid container>
            <ProgressBar color={project.colorPalettes[0].title} />
        </Grid>


        {/* Project Header & Image */}
        <CustomContainer sx={{ pt:15, pb:Mobile ? 30 : 23}}>

            <Grid container justifyContent="space-evenly" alignItems="center">

                {/*Project Header*/}  
                <Grid container item xs={5} justifyContent={Mobile ? "center" : null}>       
                    <Header color={project.colorPalettes[0].title} >
                        {project.header}
                    </Header>
                </Grid>
              

                {/*Project Image*/}
                <Grid container item  sm={7} justifyContent="center" sx={{pt: Mobile ? 5:0}}>
                    <Image main src={project.image} alt={"Project Capstone"}/>
                </Grid>
            
            </Grid>
            
        </CustomContainer>


        {/*Divider & Scroll Down CTA*/}
        <ScrollArrow />
        <Divider color={project.colorPalettes[0].title}/>
        

        {/* About The Project */} 
        <CustomContainer sx={{pt: Mobile ? 7 : 15, pb:15}}>
               
            <Grid container>
                
                <Grid item>
                    <SubTitle>About The Project</SubTitle> 
                </Grid> 

                <Grid container justifyContent="center" sx={{ pt: 13 }}>
                    <Grid item>
                        <Header color={project.colorPalettes[0].title}>{project.title}</Header>
                    </Grid>
                </Grid> 

            </Grid>

            <Grid container sx={{ pt: 7 }} justifyContent="space-around">

                {/*Description & Development Info*/} 
                <Grid item md={8}>
                    <Paragraph>{project.text}</Paragraph>
                </Grid>

                <Grid container item md={4} columnSpacing={2.5} sx={{ pt: Mobile ? 3 : 1.5 }} justifyContent={Mobile || { md:"center"} ? "center" : null}>

                    <Grid item>
                        <Stack spacing={4} sx={{ pt: Mobile ? 0.5: 0 }}>
                            <SubTitle>Role:</SubTitle>
                            <SubTitle>Client:</SubTitle>
                            <SubTitle>Date:</SubTitle>
                        </Stack>
                    </Grid>
                    
                    <Grid item sx={{ pt: Mobile ? 0: 0.5 }}>
                        <Stack spacing={4}>
                            <Paragraph >{project.role}</Paragraph>
                            <Paragraph>{project.client}</Paragraph>
                            <Paragraph>{project.date}</Paragraph>
                        </Stack>  
                    </Grid>

                    {/*CTA & Button*/} 
                    <Grid container justifyContent="center" alignItems="center" sx={{ pt: 3 }}>
                        <Grid item>
                            <Paragraph>Visit the {project.type}</Paragraph> 
                        </Grid> 
                        <Grid item sx={{pl:1, pt:1}}>
                            <ExternalLink href={project.link} target="_blank" ariaLabel={`Visit The ${project.header} ${project.type}`}>
                                <ForwardButton projectColor={project.colorPalettes[0].title}/>  
                            </ExternalLink>
                        </Grid>
                    </Grid>

                </Grid>
                
            </Grid>


            {/*Technology Stack*/} 
            <Grid container sx={{ pt: 10, pb:7}} justifyContent="center">
                <ProjectStack stack={project.stack}/>
            </Grid>


            {/*Primary Images/Remotion Video*/} 
            <Grid container justifyContent="space-evenly" sx={{ pt: Mobile ? 10 : 15 }}>
                {project.primaryImage.map((image) => (
                    <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                        {image.src}
                    </Grid>
                ))}        
            </Grid>

        </CustomContainer>
           
                    
        {/*Divider*/} 
        <Divider color={project.colorPalettes[0].title} />
       

        {/* Conception & Creation */} 
        <CustomContainer sx={{pt:15, pb:15}}>

            <Grid container>
                <SubHeader>Conception & Creation</SubHeader>  
            </Grid>

            {/* Problem Statement */}       
            <Grid container sx={{ pt: 20}} justifyContent="space-evenly"> 
            
                <Grid item sm={4}>
                    <SubTitle>{project.problemStatement}</SubTitle>
                </Grid>
                
                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>                                                                
                    <Paragraph>{project.problemText}</Paragraph>
                </Grid>

                {/*Secondary Image/Remotion Video*/} 
                <Grid container sx={{ pt: 15}} justifyContent="center" > 
                    <Grid item>
                      {project.secondaryImage} 
                    </Grid>
                </Grid>
            </Grid>

            
            {/* Solution Proposal */}  
            <Grid container sx={{ pt: 35}} justifyContent="space-evenly">
                        
                <Grid item sm={4}>
                    <SubTitle>{project.solutionStatement}</SubTitle>
                </Grid>
                
                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>                                                                
                    <Paragraph>{project.solutionText}</Paragraph>
                </Grid>

                {/*App Hierarchy Image*/} 
                <Grid container sx={{ pt: 15}} justifyContent="center" > 
                    <Grid item>
                        {project.umlDiagram}
                    </Grid>
                </Grid>

            </Grid>

                        
            {/* Functional Requirements */}   
            <Grid container sx={{ pt: 35}} justifyContent="space-evenly">
                                    
                <Grid item sm={4}>
                    <Title>Functional Requirements</Title>
                </Grid>
                
                <Grid item sm={6} sx={{pt: Mobile ? 3 : 0}}>   
                    <ul>{/*PLACED HERE INSTEAD OF COMP. B/C IT Mapped <UL> */}                                                         
                        {project.functionalPoints.map((point) => (
                            <BulletList key={point.id}>
                                {point.title}
                            </BulletList>
                        ))}
                    </ul>    
                </Grid>

                {/* Images */} 
                <Grid container sx={{ pt: Mobile ? 10 : 15 }}justifyContent="space-evenly" > 
                    {project.tertiaryImage.map((image) => (
                        <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                            {image.src} 
                        </Grid>
                    ))}        
                </Grid>

            </Grid>

        </CustomContainer>


        {/*Divider*/} 
        <Divider color={project.colorPalettes[0].title} />


        {/* Visual Essence */} 
        <CustomContainer sx={{pt:15, pb:15}}>

            <Grid container>
                <SubHeader>Visual Essence</SubHeader> 
            </Grid>


            {/* Design Statement */} 
            <Grid container sx={{ pt: 20}} justifyContent="space-evenly"> 
                <Grid item sm={4}>
                    <SubTitle>{project.designStatement}</SubTitle>
                </Grid>
                
                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>                                                                
                    <Paragraph>{project.designText}</Paragraph>
                </Grid>
            </Grid>

            
            {/* Logo Image */} 
            <Grid container sx={{ pt: 15}} justifyContent="center" > 
                <Grid item>
                    {project.logoImage}
                </Grid>
            </Grid>

            
            {/* Project Typography & Colors */}             
            <Grid container sx={{ pt: 35 }} justifyContent="space-evenly">
                
                {/*Typography*/}
                <Grid item>
                    <ProjectTypography project={project} />
                </Grid>

                {/*Color Palette*/}                                                                     
                <Grid item sx={{pt:Mobile ? 20 : 0}}>
                    <ProjectPalette project={project}/>
                </Grid>

            </Grid>


            {/* UX & UI Requirements */} 
            <Grid container sx={{ pt: 25}} justifyContent="space-evenly">

                <Grid item sm={4}>
                    <Title>UI & UX Requirements</Title>
                </Grid>
                                                                                    
                <Grid item sm={6} sx={{pt: Mobile ? 3 : 0}}> 
                    <ul>{/*PLACED HERE INSTEAD OF COMP. B/C IT MAPPED INTO <UL> */}
                        {project.uiPoints.map((point) => (
                            <BulletList key={point.id}>
                                {point.title}
                            </BulletList>
                        ))} 
                    </ul>                                                              
                </Grid>
                     
            </Grid>

            {/* Design Mockups Images */} 
            <Grid container sx={{ pt: 20 }} justifyContent="center" >
                
                <Grid item>
                    <SubTitle>Design Mockups vs Productions </SubTitle>                          
                </Grid>

                {/* Project Mockups */} 
                <ProjectMockups project={project} />      

            </Grid>

        </CustomContainer>


        {/*Divider*/} 
        <Divider color={project.colorPalettes[0].title} />
        

        {/* Existence & Reality */} 
        <CustomContainer sx={{pt:15, pb:15}}>

            <Grid container>
                <SubHeader>Existence & Reality</SubHeader>  
            </Grid>


            {/* General Statement */} 
            <Grid container sx={{ pt: 20 }} justifyContent="space-evenly"> 
                <Grid item sm={4}>
                    <SubTitle>{project.usecaseStatement}</SubTitle>
                </Grid>

                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>                                                                
                    <Paragraph>{project.usecaseText}</Paragraph>
                </Grid>
            </Grid>


            {/* Image/Remotion Video */} 
            <Grid container justifyContent="space-evenly" sx={{ pt: 15 }}>
                {project.quaternaryImage.map((image) => (
                    <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                        {image.src}
                    </Grid>
                ))}        
            </Grid>


            {/* Project UseCase Statements */} 

            {/* Statement 1 */} 
            <Grid container sx={{ pt:35 }} justifyContent="space-evenly"> 
                <Grid item sm={4}>
                    <SubTitle>{project.useStatementPoints[0].title}</SubTitle>    
                </Grid>

                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>
                    <Paragraph>{project.useTextPoints[0].title}</Paragraph>
                </Grid>
            </Grid>

            {/* Images */} 
            <Grid container justifyContent="space-evenly" sx={{pt:15}}>
                {project.useStatementImages.slice(0,3).map((image) => (
                    <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                        {image.src}
                    </Grid>
                ))}        
            </Grid> 

            {/* Statement 2 */} 
            <Grid container sx={{ pt: 35 }} justifyContent="space-evenly"> 
                <Grid item sm={4}>
                    <SubTitle>{project.useStatementPoints[1].title}</SubTitle>    
                </Grid>

                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>
                    <Paragraph>{project.useTextPoints[1].title}</Paragraph>
                </Grid>
            </Grid>

            {/* Images */} 
            <Grid container justifyContent="space-evenly" sx={{ pt: 15 }}>
                {project.useStatementImages.slice(3,6).map((image) => (
                    <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                        {image.src}
                    </Grid>
                ))}        
            </Grid> 


            {/* Statement 3 */} 
            <Grid container sx={{ pt: 35 }} justifyContent="space-evenly"> 
                <Grid item sm={4}>
                    <SubTitle>{project.useStatementPoints[2].title}</SubTitle>    
                </Grid>

                <Grid item sm={6} sx={{pt:Mobile ? 2 : 0}}>
                    <Paragraph>{project.useTextPoints[2].title}</Paragraph>
                </Grid>
            </Grid>

            {/* Images */} 
            <Grid container justifyContent="space-evenly" sx={{ pt: 15 }}>
                {project.useStatementImages.slice(6,9).map((image) => (
                    <Grid item key={image.id} sx={{pt:Mobile ? 5 : 0}}>
                        {image.src}
                    </Grid>
                ))}       
            </Grid> 


            {/* Recommendation Component of Clients/User 
            <group>
                <Html style={{ width: pixelWidth / (mobile ? 0.9 : 0.65) }} position={[ -canvasWidth / 2.3, -canvasHeight * 9.6, 0]} as="div" zIndexRange={[-1,0]}>
                    <Row className="pb-5">  
                        <Title>Enough of me. Let them tell you</Title>     
                    </Row>
                    {/*Recommendation component goes HERE /}
                </Html>
            </group> */}


            {/* Awards Component */}         
            <Grid container sx={{ pt: 35}} >
                <Awards project={project}/>
            </Grid>
            
        </CustomContainer>


        {/*Divider*/} 
        <Divider color={project.colorPalettes[0].title} />


        {/* Knowledge Attained & Acknowledgement */} 
        <CustomContainer sx={{pt:15, pb:15}}>

            <Grid container>
                <SubHeader>What I Learned?</SubHeader>  
            </Grid>

            {/* Knowledge Statements */} 
            
            <Grid container sx={{ pt: 20 }} justifyContent="space-evenly"> 
                <Grid container>                            
                    <SubTitle>{project.insightStatements[0].title}</SubTitle>                      
                </Grid>

                <Grid item sm={11} sx={{pt:Mobile ? 3 : 1}}> 
                    <Paragraph>{project.insightTexts[0].title}</Paragraph>  
                </Grid>

            </Grid>                      

            <Grid container sx={{ pt: 15 }} justifyContent="space-evenly"> 
                <Grid container>                            
                    <SubTitle>{project.insightStatements[1].title}</SubTitle>                      
                </Grid>

                <Grid item sm={11} sx={{pt:Mobile ? 3 : 1}}> 
                    <Paragraph>{project.insightTexts[1].title}</Paragraph>  
                </Grid>

            </Grid>
        
            <Grid container sx={{ pt: 15 }} justifyContent="space-evenly"> 
                <Grid container>                                 
                    <SubTitle>{project.insightStatements[2].title}</SubTitle>                      
                </Grid>

                <Grid item sm={11} sx={{pt:Mobile ? 3 : 1}}> 
                    <Paragraph>{project.insightTexts[2].title}</Paragraph>  
                </Grid>
            </Grid>           

        </CustomContainer>

            
        {/*Divider & Ending Links*/} 
        <ExtraFeatures project={project}/>
        <Divider color={project.colorPalettes[0].title} />


        {/* Project Acknowledgements */} 
        <CustomContainer sx={{pt:10, pb:10}}>

            <Grid container justifyContent="space-evenly"  >

                <Grid item>
                    <Stack spacing={5}>
                        <SubTitle>Design & Concept</SubTitle> 
                        <SubTitle>Development</SubTitle> 
                        <SubTitle>Client</SubTitle> 
                    </Stack>
                </Grid>
                
                <Grid item>
                    <Stack spacing={5}>
                        <Paragraph>{project.designConcept}</Paragraph>
                        <Paragraph>{project.development}</Paragraph>
                        <Paragraph>{project.client}</Paragraph>
                    </Stack>  
                </Grid>
                
            </Grid>
                
        </CustomContainer>

    </>
  )
}


/* Projects Container */
function Projects () { 
    
    //Param passed in from ProjectCTAComponent in Home.js
    const {subdirectory}  = useParams();

    //Projects Data filtered for matching project
    const projects = PROJECTS.filter((project) => project.subdirectory === subdirectory)

    
    return (
        <>
          
            {projects.map((project) => ( 
                <ProjectDescription key={project.id} project={project}/>
            ))}

        </> 
    )
}

export default Projects;
import React from 'react';
import {Title} from '../Shared/TypographyComponent';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';




/*
Project Typography Component
----------------------------
*/



//Web Font Loading--All Fonts will be loaded based on Project
var WebFont = require('webfontloader');
 
WebFont.load({ //PROJECT FONT STYLES MUST BE DOWNLOADED & ADDED HERE
  google: {
    families: ['Scheherazade', 'Open Sans', 'Montserrat', 'Montserrat-Alternates']
  }
});


//Custom Styling- All props passed in from Data
const StyledFont = styled('div')((props) => ({
    fontFamily: props.family,
    fontWeight: props.weight, 
    fontSize: props.xsSize || props.mdSize 
}));


//Font Style Mapped
const FontStyle = ({project}) => {

    return (
        <>
            {project.font.map((font) => (
                <StyledFont key={font.id} family={font.title} weight={font.weight} sx={{py:4, fontSize:{xs:`${font.xsSize}`, md:`${font.mdSize}`}}}>
                    
                    <Stack sx={{pb:5}} >
                        {font.title}
                    </Stack>

                    <Stack>
                        Abc123
                    </Stack>

                </StyledFont>
            ))}
        </>
    )
}


/* Project Typography Container */
function ProjectTypography ({project}) {

  //Mobile MediaQuery
  const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    return (
        <>
            <Grid container sx={{pb:Mobile ? 5 : 10}} justifyContent="center">
                <Title>Typography</Title>
            </Grid>

            <Grid item>
                <FontStyle project={project} />
            </Grid>
        </>
    )
}

export default ProjectTypography;
import Typography from '@mui/material/Typography';

/*
Typography Components For All Fonts/Styles Used On App
------------------------------------------------------
*/



/* Header Component */
const Header = ({ children, align, sx, color }) => {

  return (
    <Typography variant="h1" align={align} sx={{zIndex:'tooltip', color, ...sx}}>{/* DESTRUCTURING SX ALLOWS ME TO PASS IN WHATEVER I WANT AT COMPONENT;COLOR IS BG PROP */}
      {children}
    </Typography> 
  )
}



/* SubHeader Component */
const SubHeader = ({ children, align, sx }) => {

  return (
    <Typography variant="h2" align={align} sx={{...sx}}>
      {children}
    </Typography>
  )
}



/* Title Component */
const Title = ({ children, align, sx }) => {

  return (
    <Typography variant="h3" align={align} sx={{...sx}}>
      {children}
    </Typography>
  )
}



/* SubTitle Component */
const SubTitle = ({ children, align, sx }) => {

  return (
    <Typography variant="h4" align={align} sx={{...sx}}>
      {children}
    </Typography>
  )
}



/* Paragraph Component */
const Paragraph = ({ children, align, sx }) => {

  return (
    <Typography variant="body1" align={align} sx={{...sx}}>
      {children}
    </Typography>
  )
}



/* Caption Component */
const Caption = ({ children, align, sx }) => {

  return (
    <Typography variant="body2" align={align} sx={{...sx}}>
      {children}
    </Typography>
  )
}



export { Header, SubHeader , Title, SubTitle, Paragraph, Caption };
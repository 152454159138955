import { Container } from "@mui/material";


//Custom Container w/ Defaults & Accepts sx props

const CustomContainer = ({children, sx, props}) => {
    return (
        <Container maxWidth="xl" sx={{...sx}} {...props}>
            {children}
        </Container>
    )
}

export default CustomContainer
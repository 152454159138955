import { Divider as Div } from '@mui/material';


/*
Divider Component
-----------------
*/



const Divider = ({color, width, height, sx}) => {

    return (
        <Div sx={{  height: (height ? height : '0.3em'), width: width, backgroundColor:(color ? `${color}`: '#17D1FA'), ...sx}} />//ACCEPTS BG-COLOR PROP FOR PROJECTS
    )
}

export default Divider;

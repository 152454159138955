import React from 'react';
import { Title, Paragraph } from '../Shared/TypographyComponent';
import { Grid } from '@mui/material';


/*
Awards Component
----------------
*/


/* Awards & Certificates Image Mapping */
const AwardImages = ({project}) => {

    if ( project.awards ) {
        return ( 
            <>
               <Grid container sx={{pt:5}} justifyContent="space-evenly">
                    {project.awards.map((image) => (
                        <Grid item key={image.id}>
                            {image.src}
                        </Grid>
                    ))}

                    {/* NON-RENDERING JSON
                        <Grid item key={index}>
                            {<Image 
                                sx={{
                                    height:{ xs: '22.5em', sm: '22.5em', md: '28em' },
                                    width:{ xs: '17.5em', sm: '17.5em', md: '23em' }
                                }}  
                                src={image.src} title={image.title}
                            />}
                        </Grid>
                    */}
                </Grid> 
            </>         
        )
    
    }
    else { //If no certificates/Awards
        return (
            <>
                <Grid container item sx={{ pt:1, pl:{xs:2,md:0} }} justifyContent="center">
                    <Paragraph>Or lack thereof 😐</Paragraph>
                </Grid>
                
            </>
        )
    }
   
}



/* Awards Container */
function Awards ({project}) {
    
    return (
        <>
            <Grid container justifyContent="center"  >
                <Title>Awards</Title> 
            </Grid>

            <AwardImages project={project}/>
        </> 
    )
}

export default Awards;
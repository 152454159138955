import React from 'react';
import { SubTitle } from "./TypographyComponent";
import { Grid, Stack,useMediaQuery,IconButton } from '@mui/material';
import { useLocation,useNavigate } from 'react-router-dom';
import { BackButton } from './ButtonComponent';
import Link from './LinkComponent';
import EmailButton from '../About/EmailButtonComponent'
import ThemeToggler from '../../styles/themeToggler';


/*
Navigation Component --- NameComponent, ThemeToggler, Links
-----------------------------------------------------------
*/


const Navigation = () => {

    //Navigation Hooks
    let location = useLocation();
    let navigate = useNavigate();
    //Routes
    let homeNav = (location.pathname === '/');
    let aboutNav = (location.pathname === '/about');
    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))




    return (
        <>

            
            {
                /* Nav for Home Page */
                homeNav ?

             
                <Grid container justifyContent={"space-between" } sx={{position:"fixed", px:1.5, zIndex:1600}}>

                    {Mobile ? null :
                        <Grid item sx={{py:1.5}}>
                            <SubTitle>Nasir Abdirahman</SubTitle> 
                        </Grid>
                    }


                    {Mobile ?
                        
                        <Grid container sx={{py:1.5}} justifyContent={"space-between" }>
                            <Link to="/about" aria-label={"Meet The Developer Nasir Abdirahman"}>
                                <SubTitle>About</SubTitle>
                            </Link>
                        
                            <ThemeToggler/>
                        </Grid>
                        

                        :

                        <Stack direction="row" spacing={7} sx={{ pt:1.5 }}justifyContent="flex-end" >
                                            
                            <Link to="/about" aria-label={"Meet The Developer Nasir Abdirahman"}>
                                <SubTitle>About</SubTitle>
                            </Link>

                            <ThemeToggler/>

                        </Stack>
                    }

                </Grid>
              

                :
                

                /* Nav for About Page */
                aboutNav ?
                
               
                <Grid container justifyContent={"space-between" }sx={{position:"fixed", px: 1.5,zIndex:1600}}>

                    <IconButton disableRipple aria-label="Back" onClick={()=> navigate(-1)}>
                        <BackButton/>
                    </IconButton>

                    <Grid item sx={{py:1.5}}>
                        <EmailButton /> 
                    </Grid>

                </Grid>
              

                :
                

                /* Nav for Projects Page */
                <Grid container justifyContent={"space-between" } sx={{position:"fixed", px: 1.5, zIndex:1600}}>

                    <IconButton disableRipple aria-label="Back" onClick={()=> navigate(-1)} >
                        <BackButton/>
                    </IconButton>

                    <Stack direction="row" spacing={7} sx={{ pt:1.5}} >
                                                            
                        <Link to="/about" aria-label={"Meet The Developer Nasir Abdirahman"}>
                            <SubTitle>About</SubTitle>
                        </Link>

                        <ThemeToggler/>

                    </Stack>

                </Grid>

            }
          
  
        </>
    ) 


}



export default Navigation;
import React,{useState, useEffect} from 'react';
import LinearProgress from '@mui/material/LinearProgress';


/*
ProgressBar Component -- Projects Page
-------------------------------------
*/


const ProgressBar = ({color}) => {
  
    //State Initialized
    const [scroll, setScroll] = useState(0);
 

    useEffect(() => {

        const scrollProgress = () => {
            
            //Monitors # of pixels content is scrolled
            const totalScroll = document.documentElement.scrollTop; 

            //Returns windowHeight which is the entire height of page in px. - viewable height of page in px.
            const windowHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

            //Assign Scroll
            const scroll = (totalScroll / windowHeight) * 100;

            setScroll(scroll);
        }

        //Listening to scroll event
        window.addEventListener("scroll", scrollProgress);
        //console.log(scroll) //Test Case

        return () => {
            window.removeEventListener("scroll", scrollProgress)
        };
    },[]);


    return (
        <LinearProgress variant="determinate" value={scroll} color="inherit" 
            sx={{
                color:color,//Accepts Project Palette
                backgroundColor:"primary.secondary",
                position:'fixed',
                width: '100%'
            }}
        />  
    );
}

export default ProgressBar;
import React from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import Bio from '../data/bio';
import CustomContainer from '../layouts/container'
import Image from '../components/Shared/ImageComponent';
import {Header,SubHeader,Title, SubTitle, Paragraph} from '../components/Shared/TypographyComponent';
import Divider from '../components/Shared/DividerComponent';
import ScrollArrow from '../components/Shared/ScrollArrow';
import HelmetTag from '../components/Shared/HelmetComponent';
import Contact from '../components/About/ContactComponent';
import AreasOfExpertise from '../components/About/SkillsComponent';
import Education from '../components/About/EducationComponent';
import Recommendations from '../components/Shared/RecommendationComponent';
import ExternalLink from '../components/Shared/ExternalLinkComponent';

//WAITING FOR UPDATES
//import Greetings from '../components/About/GreetingsComponent'


/*
About Page Component
--------------------
*/



//Bio Information
function BioDescription ({ image,header,description,existenceStatements,existenceImages, links, skills, greetings, 
                            education, references, currentRole, currentOrg, orgSite }) {
  
    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    return(
        <>
   
            {/* Introduction, Contact, & Image */} 
            <CustomContainer sx={{pt:13, pb:15}}>
                
                <Grid container alignItems="center" >{/**order={{xs:1}} */}

                    <Grid container item sm={7} order={Mobile ? 1 : null}>

                        {/*Greeting*/}
                        {Mobile ? null :
                            <Grid container item>
                                
                                <Grid container item sx={{pb:3}}>
                                    {/*<Greetings greeting={greeting}  />*/}
                                    <Header sx={{color:"#17D1FA"}}>Salaam, I'm</Header> 
                                </Grid>

                                <Grid container item justifyContent="center" sx={{pb:5}}>          
                                    <Header> Nasir </Header>
                                </Grid>       

                            </Grid> 
                        }

                        {/* Mobile Greeting */}
                        {Mobile ?
                            <Grid container item justifyContent="center" sx={{pb:1}}>          
                                <Header> Hi, I'm Nasir </Header>
                            </Grid> 
                        : null}  

                        {/* Header */}
                        <Grid container item justifyContent="center">
                            <Grid item sm={11}>
                                <SubTitle align="center">{header}</SubTitle>
                                <SubTitle align="center">Currently {currentRole} at <ExternalLink href={orgSite} target="_blank" >{currentOrg}</ExternalLink></SubTitle>
                            </Grid>
                        </Grid>

                        {/*Contact Icons*/}
                        <Grid container justifyContent="space-evenly" sx={{pt:Mobile ? 3 : 7}} >                          
                            <Contact links={links}/>                                                              
                        </Grid>

                    </Grid>

                    {/*Personal Image*/}
                    <Grid  container item sm={5} justifyContent="center" sx={{pb:Mobile ? 1 : 0}}>
                        <Image src={image} sx={{ height:{ xs: '22em', sm: '32em' }, width:{ xs: '14em', sm: '21em'} }} alt={"Nasir Abdirahman Throwing Up The Deuces"} />             
                    </Grid>
                    

                </Grid>

            </CustomContainer>


            {/*Divider & Scroll Down CTA*/}
            <ScrollArrow />
            <Divider/>


            {/* Summary and Areas of Expertise */} 
            <CustomContainer sx={{ pt:15,pb:15}}>
                    
                <Grid container justifyContent="center" >
                    <Grid item sm={10}>
                        <Title>{description}</Title>
                    </Grid>
                </Grid>


                {/* Areas of Expertise Header */}
                <Grid container justifyContent="space-evenly" sx={{pt:35}}>

                    <Grid item>
                        <Title align="center">Areas of Expertise</Title>
                        <Paragraph align="center">A few things I build with</Paragraph>
                    </Grid>

                    <Grid container justifyContent="space-evenly" sx={{pt:7}}> 
                        <AreasOfExpertise skills={skills} />
                    </Grid>

                </Grid>

                {/* Canva Quote 1/}
                {mobile ? 
                    null //Proper use here?
                    :
                    <MultilineText top left size={0.4} lineHeight={mobile ? w / 8 : w / 10} position={[ mobile ? w / 4 -1 -1 : w / 2.2, 0, -1]} color={"#17D1FA"}  
                        zIndexRange={[-1,0]} text={" An \n Engineer \n With A \n Designer's \n Eye \n &  \n A Designer \n With An \n Engineer's \n Mind"} 
                    /> 
                }*/}

            </CustomContainer>


            {/*Divider*/} 
            <Divider/>
            

            {/* The Existence */}
            <CustomContainer sx={{ pt:15, pb:15}}>
             

                <Grid container >
                    <Grid item>
                        <SubHeader>My Existence</SubHeader>
                    </Grid>
                </Grid>
                    
                
                {/* Personal Statements */}
                <Grid container sx={{pt:20}}>

                    <Grid item sx={{pl:Mobile ? 0 : 5}}>                            
                        <Title>In Life</Title>                    
                    </Grid>
                
                    <Grid container justifyContent="center">

                        <Grid item sm={10} sx={{pt:3}}>
                            <Paragraph>{existenceStatements[0].title}</Paragraph>
                            <Paragraph sx={{pt:2}}>{existenceStatements[1].title}</Paragraph>  

                        </Grid>

                    </Grid>

                    <Grid container justifyContent="space-evenly" sx={{pt:15}}>

                        <Grid item>
                            <Image src={existenceImages[0].src} title={existenceImages[0].title}  sx={{ height:{ xs: '35em', sm: '42em' }, width:{ xs: '20em', sm: '23em'} }}/>
                        </Grid>

                        <Grid item sx={{pt:{xs: 3, md:0}}}>
                            <Image src={existenceImages[1].src} title={existenceImages[1].title} sx={{ height:{ xs: '25em', sm: '36em' }, width:{ xs: '20em', sm: '29em'} }}/>
                        </Grid>

                    </Grid>


                </Grid>


                {/* Professional Statements */}
                <Grid container sx={{pt:25}}>

                    <Grid item sx={{pl:Mobile ? 0 : 5}}>                            
                        <Title>At Work</Title>                     
                    </Grid>
                
                    <Grid container justifyContent="center">

                        <Grid item sm={10} sx={{pt:3}}>
                            <Paragraph>{existenceStatements[2].title}</Paragraph>
                            <Paragraph sx={{pt:2}}>{existenceStatements[3].title}</Paragraph>  
                            <Paragraph sx={{pt:2}}>{existenceStatements[4].title}</Paragraph>  
                        </Grid>

                    </Grid>

                </Grid>
                
            </CustomContainer>


            {/* The Response */} 
            <CustomContainer maxWidth="false" sx={{ pt:25,pb:15}}>

                {/* Quote 3 
                <Grid container>

                   <Grid item wrap='nowrap' xs={1}>
                  
                        <Header sx={{color:"#17D1FA"}}> It Is Never Too Late To Be What You Might Have Been </Header>       
                   
                    </Grid>

                </Grid>*/}

                          
                {/* Education */} 
                <Grid container /*sx={{pt:15}}*/ >

                    <Grid item sx={{pl:Mobile ? 0 : 5}}>                            
                        <Title>Education</Title>      
                    </Grid>

                    <Grid container sx={{pt:15}}> 
                        <Education education={education}/>
                    </Grid>

                </Grid>


                {/* Recommendations */} 
                <Grid container sx={{pt:30}}>

                    <Grid container item justifyContent={"center"}>                            
                        <Title>Recommendations</Title>      
                    </Grid>

                    <Grid container sx={{pt:15}}> 
                        <Recommendations references={references}/>
                    </Grid>
                </Grid>

            </CustomContainer>    


            {/*Divider*/} 
            <Divider />


            {/* Contact Footer */} 
            <CustomContainer sx={{ pt:15,pb:15}}>

                {/* Footer */}
                <Grid container>

                    <Grid container>
                        <Header sx={{color:"#17D1FA"}}>
                            Let's Create As We Speak...
                        </Header>

                        <SubHeader sx={{pt:3, pl:Mobile ? 0 : 7}}>To collaborate is inevitable</SubHeader> 
                    </Grid>
                    
                </Grid> 


                <Grid container sx={{pt:Mobile ? 7 : 15}}>

                    <Grid item sx={{pl:Mobile?0:5}} sm={7}>                            
                        <SubTitle>Contact me for collaborations, freelance projects and leg days at the gym.</SubTitle>
                    </Grid>

                    {/* Contact Links */} 
                    <Grid container item sm={5} sx={{pt:Mobile ? 7 : 0}} justifyContent="space-evenly"> 
                        <Contact links={links}/> 
                    </Grid>
                    
                </Grid>
                
            </CustomContainer>

        </>

    )
}



/* About Container */
function About () { 


    return (
        <>
            {/* About Helmet Tag */}
            <HelmetTag title="Nasir Abdirahman - About"
                description="A brief bio of Nasir Abdirahman"
                href="https://www.nasirabdirahman.com/about"
            />

            {/* Biography data */}
            {Bio.map((props, index) => (
                <BioDescription key={index} {...props}  />
            ))}  
                    
        </> 
    )
}

export default About;

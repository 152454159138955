import React from 'react'; 
import { Paragraph } from './TypographyComponent';
import { Grid } from '@mui/material';
import { styled } from '@mui/system';

/*
Image Component : Accepts Props For Image Sizing
------------------------------------------------
*/
/* REACT-SPRING!!!! *//* REACT-SPRING!!!! *//* REACT-SPRING!!!! *//* REACT-SPRING!!!! */

//Custom Styling
const StyledImage = styled('img')((props) => ({
    borderRadius:'20px',
    borderStyle: 'solid',
    borderWidth: '1px',
}));


const Image = ({children, src, alt, title, main, app, web, certificate,dual, sx}) => {
    
    //Main image styling can be passed in as props
    //App image styling can be passed in as props
    //Web image styling can be passed in as props
    //Default image styling can be passed in 
    //Certificate Image added- due to JSON rendering for awards

    return (
        <>
            <StyledImage src={src} alt={alt}
                sx={{ 
                    height: main ? { xs: '15em', sm: '15em', md: '25em' }
                        : app ? { xs: '29em', sm: '29em', md: '37.5em' }
                        : web ? { xs: '17em', sm: '22em', md: '25.5em',lg: '33.5em' } 
                        : certificate ? { xs: '22.5em', sm: '22.5em', md: '28em' }
                        : dual ? { xs: '17em', sm: '18em', md: '22.5em' } 
                        : sx, 
                    width: main ? { xs: '22.5em', sm: '22.5em', md: '48em' } 
                        : app ? { xs: '14em', sm: '14em', md: '18.5em' }
                        : web ? { xs: '24em', sm: '35em', md: '45em', lg: '58em' }
                        : certificate ? { xs: '17.5em', sm: '17.5em', md: '23em' }
                        : dual ? { xs: '24em', sm: '18em', md: '35em' } 
                        : sx,
                    ...sx
                }}
            >

            {children}

            </StyledImage>

            {/* Accompanying Title/Description */}
            <Grid container justifyContent="center">
                <Paragraph>{title}</Paragraph>
            </Grid>
           
        </>
    )
};


export default Image;
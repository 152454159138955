/*
Project Data
------------
*/
import Image from "../components/Shared/ImageComponent"


//\u00a0 = Space
const PROJECTS = [

    //Vaccine WA
    {
        id:0,
        subdirectory:"VaccineWA",
        header:"VaccineWA",
        summary: "A mobile app that helps users search for & book a Covid-19 vaccine",
        type:"Mobile-App",
        //Link to Site/App
        link:"https://expo.dev/@abdul-nasir-abdirahaman/VaccineWA",
        //Main Canva Image
        image:"/images/projects/VaccineWA/covid-19.png", 
        title:"Covid-19 Vaccine Finder",
        text:"I embarked on a mission to assist my fellow Washington residents in finding a covid-19 vaccine. I set out to build an intuitive, simple to use application that provided users the ability to search for vaccine providers within their Zip Code. Filtering through a data set of the Dept. of Health’s providers, the app retrieves specified provider information, including contact information, availability of vaccine types, appointment status and other pertinent information. Users can contact the provider and schedule an appointment, view the vaccine types available at that location, (if provided). and receive directions using their phone’s native map app. The app also seeks to educate users on Covid-19, presenting information approved by the CDC and WA Dept. of Health.",
        role:"Design & Development",
        client:"Self-Initiated",
        date:"March 2021 - April 2021",
        stack: {
            framework: { src:"/icons/react.svg", title:"React Native" },
            styling: { src:"/icons/css-3.svg", title:"Custom CSS3" },
            database: { src:"/icons/json.svg", title:"Local JSON" },
            server: { src:null, title:null },
            hosting: { src:null, title:null},
            supplemental:[
                { id:0, src:"/icons/expo-icon.svg", title:"Expo SDK" },
                { id:1, src:"/icons/babel.svg", title:"Babel" },
                { id:2, src:"/icons/github-icon.svg", title:"Github" },
            ]
        },
        primaryImage:[
            {id: 0, src: <Image src={"/images/projects/VaccineWA/HomePage.jpg"} app title="Home Page"  alt="Home Page" />},
            {id: 1, src: <Image src={"/images/projects/VaccineWA/ResultsComponent.jpg"} app title="Results Page" alt="Results Page"/>},
            {id: 2, src: <Image src={"/images/projects/VaccineWA/ProviderComponent.jpg"} app title="Provider Page" alt="Provider Page"/>}
        ],
        //Biggest Obstacle Or Reason for Creation
        problemStatement: "The struggle to find a vaccine",
        problemText:"The vaccine was shipped, ready and people were having difficulties receiving it. The struggle of finding providers that offered the vaccine was further compounded by the strain of booking an appointment. I discovered that the currently available sites were difficult to navigate and may prove inaccessible to the most vulnerable users. It was frustrating, grueling and counter to the vaccination drive to save lives.",        
        //Competitor Image
        secondaryImage: <Image src={"/images/projects/VaccineWA/CovidWA.jpg"} title="CovidWA was the only site available at the time" web />,
        //Solution that was formulated
        solutionStatement:"A cross-platform mobile application", 
        solutionText:"An issue of this severity needed simplicity. A simple user interface, instructions and widely disseminated. It needed to be a mobile App. 55-85% of web traffic is accessed via consumer phones. Differentiating between building an iOS and Android app first would waste valuable time. The obvious choice was React-Native. The information needed to be easy to digest and acted upon. Provide users the ability to find a provider, contact a provider and receive the vaccine effortlessly.",        
        //UML Diagram
        umlDiagram: <Image src={"/images/projects/VaccineWA/AppHierarchy.png"} title="App Hierarchy" web />,
        //Desired Functionality
        functionalPoints:[
            {id:0, title: "Search function that filters ALL the state's providers by zip code to retrieve specified provider info."},
            {id:1, title: "Concisely present the provider contact information and the provide the ability to establish communication through appropriate channels."},
            {id:2, title: "Present pertinent, accurate, and up-to-date information supplied by each exclusive provider."},
            {id:3, title: "Provide indisputable state and federal guidelines on Covid-19 in an efficient, structured manner from trustworthy and assertive authoritative sources."},
        ],        
        tertiaryImage:[
            {id: 0, src: <Image src={"/images/projects/VaccineWA/NavigationDrawer.jpg"} app title="Navigation Drawer"  alt="Navigation Drawer" />},
            {id: 1, src: <Image src={"/images/projects/VaccineWA/VaccinePhasesComponent.jpg"} app title="Vaccine Phases Page" alt="Vaccine Phases Page"/>},
            {id: 2, src: <Image src={"/images/projects/VaccineWA/FaqComponent.jpg"} app title="FAQ Page" alt="FAQ Page"/>}
        ],
        //Design Goal
        designStatement:"Simplicity is actually difficult",
        designText:"Build for what it can be. I designed and conceived of this app as being used by millions in Washington (I know, I know). In order for them to use it, it needed to be intuitive. Can my user understand how to use it instinctively and if not, how do you explicitly present unobtrusive assistance? The instructions and end goal for the app must be clear. It's purpose ascertained at first glance. The most difficult part for me is design. I had to step out of my comfort zone, engineering, and wade into the user interface and experience deep end.",        
        //Font Family Name
        font:[
            {id:0, title:"Scheherazade",weight:"700", xsSize:"50px",mdSize:"75px"},
            {id:1, title:"Open Sans",weight:"400", xsSize:"50px",mdSize:"75px"}
        ],
        colorPalettes: [
            { id:0, title:"#70BAFF"},
            { id:1, title:"#B1DDF9"},
            { id:2, title:"#FFFFFF"},
        ],
        logoImage:<Image src={"/images/projects/VaccineWA/logo.png"} web title="App Logo"  alt="App Logo" />, 
        //Desired UI & UX 
        uiPoints:[
            {id:0, title: "Intuitive navigation and effortless user-flow oriented to fulfilling the user's desire."},
            {id:1, title: "Uncomplicated visual design and information architecture equals effortless moments of interaction. Interface visually 'communicates' with the user."},
            {id:2, title: "A three-step clear and understandable path to the goal. The design ensures that the road is visible, predictable and understandable. Clear visual hierarchy."},
            {id:3, title: "A responsive interface, contrasting palettes, clear fonts, and effective call to actions create visual rhythm and balance. All aligning towards the user's current convenience. Mobile, desktop or tablet."},
            {id:4, title: "Accessibility, accessibility, accessibility. Provide an option of equal access for all."},
        ],
        uiMockups:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/homeMU.png" app title="Home Page Mockup"/>}, 
            {id: 1, src: <Image src="/images/projects/VaccineWA/HomePage.jpg" app title="Home Page"/>}, 
            {id: 2, src: <Image src="/images/projects/VaccineWA/resultsMU.png" app title="Results Page Mockup"/>}, 
            {id: 3, src: <Image src="/images/projects/VaccineWA/ResultsComponent.jpg" app title="Results Page"/> }, 
            {id: 4, src: <Image src="/images/projects/VaccineWA/providerMU.png" app title="Provider Details Mockup"/>},
            {id: 5, src: <Image src="/images/projects/VaccineWA/ProviderComponent.jpg" app title="Provider Details"/>},
            {id: 6, src: <Image src="/images/projects/VaccineWA/vaccinephasesMU.png" app title="Vaccine Phases Mockup"/>},
            {id: 7, src: <Image src="/images/projects/VaccineWA/VaccinePhasesComponent.jpg" app title="Vaccine Phases"/>}
        ],
        //General Victory Statement
        usecaseStatement:"The Covid-19 Vaccine Finder",
        usecaseText: "After three weeks of marathon programming to finish my app, I submitted my project on the final hour for my bootcamp's Graduate with Honors competition and won! In 3 weeks I went from a prototype design on Axure to a fully functioning mobile app. I accomplished my project's objectives and fully engineered my requirements.",        
        quaternaryImage:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/QuestionZoom.jpg" app title="Eligibility CTA"/>},
            {id: 1, src: <Image src="/images/projects/VaccineWA/EligibilityPopOut.jpg" app title="Eligibility PopOut"/>},
            {id: 2, src: <Image src="/images/projects/VaccineWA/EligibilityComponent.jpg" app title="Eligibility Page"/>}
        ],
        //General Statements on Use Cases  
        useStatementPoints:[
            {id:0, title: "Specific and detailed information on the vaccine providers"},
            {id:1, title:  "An aesthetically pleasing visual design"},
            {id:2, title: "Meticulous, detailed information from esteemed sources"},
        ],     
        useTextPoints:[
            {id:0, title: "A simple zip code starts the journey. The search function forages through the Dept. of Health's data set of all the providers in the state that are offering the vaccine. The providers offering vaccines are then displayed alphabetically. Presenting applicable information on the provider; their name, address, telephone, email and web site. An important consideration at the time, was vaccine type and availability. An initial glance was enough to ascertain whether vaccines were in stock at this location, what type of vaccine it was and when this information was last updated. Educating users on alternate options was a high consideration. Displaying zip codes that were within 15 miles of their location and providing that portal to view more providers was mandatory."},
            {id:1, title: "The font choice, color schema and concise instructions accomplished the UI and UX goals outlined from the project's launch. Ensuring that the vulnerable population had no difficulties using the app was obligatory. An incessant question I had was whether or not my user's behavior corresponded to my planned scenario? I proceeded to test my app on my elderly friends and my non-native english speaking friends to verify that the flow was according to plan. The procedural steps they followed to accomplish their goal matched my test case! The key moments of interactions were entirely how I envisioned it. An extra caveat was my intense focus on spacing between elements to ensure that links, images, and text were not jumbled together or interfering with functionality."},
            {id:2, title: "The CDC and the Washington Dept. of Health were my ONLY sources. This was a necessary requirement. The Covid-19 pandemic was, and still is, battling an insidious enemy, misinformation. For my app to assist in this battle I knew that my data could only be sourced from these two reputed organizations. Thankfully their data sets were available for download. Sourcing the providers information from the DOH site was a double-edged sword. I discovered that some data was suffering from inevitable human-errors and had to rectify it by doing research on individual providers. Building the foundations of the app on peer-reviewed, established scientific guidelines was imperative."},
        ],
        useStatementImages:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/providerZoom.jpg" app title="Vaccine Types Available"/>} ,
            {id: 1, src: <Image src="/images/projects/VaccineWA/InstructionsZoom.jpg" app title="Detailed Instructions"/>} ,
            {id: 2, src: <Image src="/images/projects/VaccineWA/ZipcodeRadiusZoom.jpg" app title="Radius Zipcodes Shown"/>} ,
            {id: 3, src: <Image src="/images/projects/VaccineWA/SplashScreen.jpg" app title="Splash Screen"/>} ,
            {id: 4, src: <Image src="/images/projects/VaccineWA/WAPhasesZoom.jpg" app  title="WA Vaccine Equity Details"/>} ,
            {id: 5, src: <Image src="/images/projects/VaccineWA/EligibilityZoom.jpg" app title="Eligibility Questions"/>} ,
            {id: 6, src: <Image src="/images/projects/VaccineWA/FAQZoom.jpg" app title="In-depth Vaccine Info"/>} ,
            {id: 7, src: <Image src="/images/projects/VaccineWA/FAQZoom2.jpg" app title="FAQ Accordion"/>} ,
            {id: 8, src: <Image src="/images/projects/VaccineWA/FAQZoom3.jpg" app title="Sources Revealed"/>}

        ],
        awards:[
            {id:0, src: <Image src= "/images/about/education/HONORS STUDENT Front-End Web & Mobile Development Certificate.png" certificate title="Nucamp Project Honor Certificate"/>} ,  
        ],   
        //Insight Statements 
        insightStatements:[
            {id:0, title: "Building for others was, and is, software's goal"},
            {id:1, title: "Adapting and pivoting when faced with obstacles is mandatory...Agile?!"},
            {id:2, title:"Your satisfaction for your work is the best reason you can have"},
        ],
        insightTexts:[
            {id:0, title:"With the countless suffering that Covid-19 has caused on my community, state and country; building this app gave me a way to provide help using my expertise. I witnessed the current situation and how my fellow neighbors were having difficulties receiving a vaccine. Building this personal project, under stress, while juggling personal responsibilities, contrary to what I thought it would be, was exhilarating. I set high standards for myself and I went for it. The exhaustion I felt was overshadowed by the exuberance of building something I felt was contributing to alleviating another's difficulties. This project revealed to me that I am interested in social entrepreneurship, building something that helps others, and joining those who contribute to and are fueled by similar endeavors."},
            {id:1, title:"During the development process, I discovered that the current tech stack I had planned on using and diagrammed with, would not suffice for my project. From version inconsistencies to dependency conflicts. Initially it was intimidating, I had to read new documentation and learn as I was building the app. I learned to adapt and update as I programmed. Reading documentation, searching for alternative solutions, and innovating based on feedback from test users.  I was exercising, unbeknownst to me, at the time, Agile  SDLC. The valuable skill of reading documentation, that my instructors raved about, became apparent. I was able to use new technologies, while simultaneously learning them, for my project because of the quick feedback loop I built, resourcefulness, and grit to finish my app with all the features I prototyped."},
            {id:2, title:"When I presented my app to my graduating class. I was selected for Honors. I was so grateful and honored. I was encouraged to publish it and go live on the app stores. I agreed because I saw it as a necessity. I contacted a group of volunteers that built a vaccine finder site similar to what I just created, but desktop. I was prepared to offer the app for free, I just needed to connect their data source. They were receiving live provider updates from the DOH (who just recently closed the data source my app was using). After countless alternating emails, and finally establishing contact. The goal was to potentially provide the front end design to the DOH and her partners directly. Despite our best efforts, it never materialized. This failed to stop me. I moved ahead and decided to publish the app myself and create a backend server that crawled the vaccine providers' site for my data. Then, the unfortunate news of Apple/Google locking out any Covid-19 App from publishing without express approval of Health orgs. was revealed to me. This was disheartening. I felt like I had lost. Although I was sad, I remembered how much fun I had building it, prototyping it, and conceiving it. Having the courage to cross that shadow and make it real. I did not witness my project being used as I wished, but I knew I loved the process, its highs and lows. My satisfaction with my product and my users (even potential users) is what thrilled me. And, hey, at least my friends set appointments.😁"},
        ],
        //Videos, Publications etc.
        extraLinks:[
            {
                id:0,
                text:"Youtube presentation video ( I promise I dont speak this fast irl 😅) ", 
                link:"https://www.youtube.com/watch?v=ZsG4ZD4GY5s"
            }
        ],
        designConcept:"Nasir Abdirahman",
        development:"Nasir Abdirahman",
    },

    //Personal Portfolio
    {
        id:1,
        subdirectory:"PortfolioSite",
        header:"Portfolio\u00a0Site",
        summary: "My personal site that showcases me",
        type:"Site",
        //Link to Site/App
        link:null,
        //Main Canva Image
        image:"/images/projects/PersonalSite/portfolioCover.png", 
        title:"Reinventing My footprint",
        text:"How do you showcase a site that someone is currently visiting? Well, let us start with why I decided to rebuild my site. Performance, design and new technologies. Poor performance damages 'findability'. A fast site is just better, for a myriad of reasons, but suffice to say, Google says so. I also wanted to upgrade my UI and UX skills, create beautiful moments of interaction (all the cool kids are doing it), and practice test-driven development.(oooo BUZZWORD!). In addition, the code is 'nothing but hooks', functional components have conquered the landscape and reusable components are compulsory. I discovered new libraries that make life simple and I am obsessed with self-torture. ie. Learning a new technology and using it at the same time. Hope you like the improvements!",        role:"Design & Development",
        client:"A stubborn perfectionist",
        date:"Dec 2021 - Jan 2022",
        stack: {
            framework: { src:"/icons/react.svg", title:"React JS" },
            styling: { src:"/icons/material-ui.svg", title:"Material UI" },
            database: { src:"/icons/json.svg", title:"Local JSON" },
            server: { src:null, title:"Serverless" },
            hosting: { src:"/icons/aws-amplify.svg", title:"AWS Amplify" },
            supplemental:[          
                { id:0, src:"/icons/react-router.svg", title:"React-Router" },
                { id:1, src:"/icons/react-spring.svg", title:"React-Spring" },
                { id:2, src:"/icons/use-gesture.svg", title:"use-gesture" },
                { id:3, src:"/icons/jest.svg", title:"Jest" },
                { id:4, src:"/icons/github-icon.svg", title:"Github" },
            ]
        },
        primaryImage:[
            {id: 0, src: <Image src={"/images/projects/PersonalSite/HomeDark.png"} dual title="Home Page - Dark"  alt="Home Page" />},
            {id: 1, src: <Image src={"/images/projects/PersonalSite/HomeLight.png"} dual title="Home Page - Light"  alt="Home Page" />},
        ],
        //Biggest Obstacle Or Reason for Creation
        problemStatement: "3D graphics implementation is difficult, responsiveness needed help, and I desired new tech",
        problemText:"My site was previously using webGl(by way of Three.JS). I loved it! BUT, the learning curve was steep and the community, albeit nice, are far and few between. I spent countless hours learning difficult concepts and although I was initially satisfied with my site, I wanted to move on. I sought to build a reusable component library, like lego bricks, that I could just port over and build entire pages. A friendly, beautiful and inviting user-experience that would make for good first impressions.",        
        //Competitor Image
        secondaryImage: <Image src={"/images/projects/PersonalSite/OldSite.png"} title="Old 3D Home Page" web />,
        //Solution that was formulated
        solutionStatement:"Dont reinvent the wheel. Just add spinners",
        solutionText:"Building a library of reusable components was new to me. I wanted complete control without limitations. I chose MUI, due to its wide variety of components and it's extremely configurable nature. The community is active (the founders respond to issues 🤯), the documentation is excellent and its pre-built components can be altered unremittingly. Implementing TDD was revolutionary for me. Thinking about testing, app hierarchy, and relationships between components before even programming seemed daunting. I was pleasantly shocked to realize that it sped up my development cycle. A 40+ component library (pages & hooks too) built in one month for me was astounding.",        
        //UML Diagram
        umlDiagram: <Image src={"/images/projects/PersonalSite/PersonalSiteUML.svg"} title="UML diagram showcases component hierarchy" web/>,
        //Desired Functionality
        functionalPoints:[
            {id:0, title:"Build reusable pure components. Create as if others will use my library to create more things."},
            {id:1, title: "Construct clean and maintainable code. Preserve complete and assiduous testing, only 100% coverage allowed."},
            {id:2, title: "Project showcases will be reactive and dependent on data. Perpetuate conditional rendering meticulously to reduce bugs and protract the specter of multiple pages."},
            {id:3, title:  "Create a continuous integration and deployment pipeline on a cloud provider. Agile development and server-less play well together. By simplifying my packaging and deployment, I will reduce my time to production."},
        ],         
        tertiaryImage:[
            {id: 0, src: <Image src={"/images/projects/PersonalSite/projectHome.png"} dual title="Project Home"  alt="Project Home" />},
            {id: 1, src: <Image src={"/images/projects/PersonalSite/projectDetails.png"} dual title="Project Synopsis" alt="Project Synopsis"/>},
        ],
        //Design Goal
        designStatement:"Attention to a singular goal",
        designText:"The home page showcases the projects. Individual project pages showcase details and decision-making processes. The About page shows a sliver of my personality. Singularity in attention, details amplified, and in-depth explanations. Every page had a theme, a particularity that I only spoke of. I chose a clean, mute design with an unimposing font type in order to draw the visitor's attention to each subject matter exclusively. ",        
        //Font Family Name
        font:[
            {id:0, title:"Montserrat",weight:"600", xsSize:"31px",mdSize:"60px"},
            {id:1, title:"Montserrat\nAlternates",weight:"900", xsSize:"31px",mdSize:"55px"}
        ],
        colorPalettes:[
            { id:0, title:"#17D1FA"},
            { id:1, title:"#000000"},
            { id:2, title:"#FFFFFF"},
        ],
        logoImage:<Image src={"/images/projects/PersonalSite/emojicon.png"} web title="Emojicon Logo - In case you wondered who was Apple's muse ☝🏾"  alt="Emojicon Logo" />, 
        //Desired UI & UX 
        uiPoints:[
            {id:0, title:"A directed user-flow from over-view to full immersion in details. The site shall exhibit the projects and the engineer behind them, this is the main focus."},
            {id:1, title:"Administer information architecture to accurately display my thought-process and style of engineering. Use interactions moderately and only to communicate with my visitor."},
            {id:2, title:"A three-step process. Clear visual hierarchy leading to the main project showcases. Uncomplicated guidance leading straight to the information."},
            {id:3, title:"A responsive interface, contrasting palettes, clear fonts, and effective call to actions create visual rhythm and balance."},
            {id:4, title:"Accessibility, accessibility, accessibility. Provide an option of equal access for all."},
        ],
        uiMockups: null,
        //General Victory Statement
        usecaseStatement:"Total of four pages, three custom hooks, two theme palettes and 40+ components using TDD",
        usecaseText: "The landing page immediately draws your attention to the projects. A brief header, description and display image garners the user's attention. Selecting a project causes a smooth transition to the projects page. The page is uniquely filled by each projects' data, dynamically rendering and describing the design & development in detail. The color palettes used for each project are passed in as props and control various design quirks. Brief sections of each project are displayed with accompanying images and descriptions inviting the user to explore more. All of this culminating in a brief synopsis of the knowledge I gained building the project. The About page exhibits my contact info, skill set and snippets of my cool life. And an adorable kitten graces the page.",        
        quaternaryImage:[
            {id: 0, src: <Image src="/images/projects/PersonalSite/skillsWhite.png" dual title="Skills Section"/>},
            {id: 1, src: <Image src="/images/projects/PersonalSite/designChoices.png" dual title="Design Choices Section"/>},
        ],
        //General Statements on Use Cases  
        useStatementPoints:[
            {id:0, title: "Home Page Smooth Scrolling"},
            {id:1, title: "Theme Control, Typography & Colors"},
            {id:2, title: "Significant Interactions & personality"},
        ],     
        useTextPoints:[
            {id:0, title:"The glossy scrolling between projects you feel on the home page is achieved with a combination of react-use-gesture and lethargy. Use gesture allows me to bind elements to an event, in this case, the scroll(useWheel). Lethargy helps my site distinguish between scroll events initiated by the user, and those by inertial scrolling. By combining these two with a clamping function, I am able to have that smooth effortless scrolling effect and the 'locked-in' feel to each project image and description."},
            {id:1, title:"I bestowed upon my vampire users the gift of dark mode. I  implemented two theme variations using MUI and react context. The theme is controlled by a button on the nav bar that flips the theme. In addition, I constructed a unique typography component that displays an example of the font type chosen for each individual project, in its native style! Using a font loader package that only loads the font-family for each project when it is selected, saving valuable resources and load time. Each project obviously has its own unique color palette and I thought, 'wouldn't it be cool if I could not only display the color palettes but also use the colors as design details'? I built components that allowed me to pass in as props the primary color used for the project to individual components on the details page. Permeating each project showcase with its own unique styling. Peep the progress bar, CTA arrow, and dividers."},
            {id:2, title:"I wanted my site to have subtle but steering interactions. A CTA arrow button that pops forward or backward, on hover, depending on its direction. My email CTA on my about page, has an emoji with my likeness who slowly but surely guilt trips users into emailing me by changing his emotions. My love for UI 'nods of approval' is evident. That email you copied, that link you've selected and that section you've entered. I made sure to add these little 'hurrahs' to let my user know I am proud of them. One of my favorite sections is my recommendations component. They came from two individuals that blessed me with their knowledge. I created a component to showcase them and their kind words. It is set on an interval that loops through the recommenders and has a progress circle that displays it's advancement schedule. In addition, a hover hook pauses the interval for reading, in case my user wants to get teary-eyed. This was all built with hooks and love."},
        ],
        useStatementImages:[
            {id: 0, src: <Image src="/images/projects/PersonalSite/functionalReq.png" dual title="Fan of two text & three image panels"/>},
            {id: 1, src: <Image src="/images/projects/PersonalSite/profile.png" dual title="Profile section"/>},
            {id: 2, src: <Image src="/images/projects/PersonalSite/uiReq.png" dual title="Balanced UI text"/>},
            {id: 3, src: <Image src="/images/projects/PersonalSite/emailButton.png" dual title="Email Button"/>},
            {id: 4, src: <Image src="/images/projects/PersonalSite/hoveredEmail.png" dual title="Hover Effect"/>},
            {id: 5, src: <Image src="/images/projects/PersonalSite/clickedEmail.png" dual title="Nod of approval"/>},
            {id: 6, src: <Image src="/images/projects/PersonalSite/recommender.png" web title="Recommendations section"/>},
        ],
        awards:null,
        //Insight Statements 
        insightStatements:[
            {id:0, title: "Love my hooks"},
            {id:1, title: "Test-driven development is a necessity"},
            {id:2, title: "Documentation & quick iterations"},
        ],
        insightTexts:[
            {id:0, title:"Akin to most new engineers, I learned react with it's class components. Then I read about all the bloggers professing their new found obsession with hooks.  So, I challenged myself to build an entire web app with react hooks. THEN I figured out why they were obsessed. My code was cleaner and easier to understand. The learning curve is initially difficult to comprehend but once I got the hang of it, I started creating custom hooks. The reusable aspect is it's strongest argument. Controlling events with hooks and then being able to reuse them throughout an application is exciting. Hooks that control state transitions like hover, scroll, and intervals are just the tipping point for me. Definitely will continue to explore."},
            {id:1, title:"This process has helped me build quickly, efficiently and effortlessly. Due to this being my first time, I found myself initially struggling to create tests and app hierarchy before programming but once I finished that preliminary step, things became smoother. Immediately I realized that I was coding faster, my features were efficiently modularized and I could refactor issues faster. I was thinking less and just building. The 'big picture' concept thinking was already accomplished. I knew what I wanted my code to do and I was laser-focused on implementing it. By initially building micro-features with a clean interface and making sure they were operating detached, I was creating systematic and orderly code. I also unearthed issues faster, well the tests did. By using this development process, my errors in code manifested themselves faster and I was able to restructure expeditiously. Was it necessary to build a personal project with this methodology? No. But I wanted to learn this necessary skill."},
            {id:2, title:"I practiced the art of reading new documentation, searching for solutions incessantly and giving myself deadlines and tickets. I treated this project like an assignment. I gave myself a strict three week deadline. Creating weekly tickets for objectives that had to be completed was stressful but exciting. I came across new material and concepts that were foreign to me. Understanding how to learn by 'myself' is a necessary and practical skill. Even more important is being resourceful and researching code off stack overflow, learning why it works(if it does), and porting it over for my use-case. The needless stress I created for myself was geared towards preparing me for more difficult roles and projects. Pushing myself to ignore my cute kitten during coding hours, albeit difficult, was satisfying. If you haven't worked with a kitten in your home, then you dont know what discipline is."},
        ],
        //Videos,Publications etc.
        extraLinks: null,
        designConcept:"Nasir Abdirahman",
        development:"Nasir Abdirahman",
    },

    //Transition Tech
    {
        id:2,
        subdirectory:null,//NULL = UNDER CONSTRUCTION
        header:"Stealth\u00a0Project",
        summary: "",
        type:"Web App",
        //Link to Site/App
        link:"https://expo.dev/@abdul-nasir-abdirahaman/VaccineWA",
        //Main Canva Image
        image:"/images/projects/Stealth/stealth_logo.png", 
        title:"The region that became home",
        text:"I embarked on a mission to assist my fellow Washington residents in finding a covid-19 vaccine. I set out to build an intuitive, simple to use application that provided users the ability to search for vaccine providers within their Zip Code. Filtering through a data set of the Dept. of Health’s providers, the app retrieves specified provider information, including contact information, availability of vaccine types, appointment status and other pertinent information. Users can contact the provider and schedule an appointment, view the vaccine types available at that location, (if provided). and receive directions using their phone’s native map app. The app also seeks to educate users on Covid-19, presenting information approved by the CDC and WA Dept. of Health.",
        role:"Design & Development",
        client:"Self-Initiated",
        date:"March 2021 - April 2021",
        stack: {
            framework: { src:"/icons/react.svg", title:"React Native" },
            styling: { src:"/icons/css-3.svg", title:"Custom CSS3" },
            database: { src:"/icons/json.svg", title:"Local JSON" },
            server: { src:"", title:"Server Not Applicable" },
            hosting: { src:"", title:"Hosting Not Applicable" },
            supplemental:[
                { id:0, src:"/icons/expo.svg", title:"Expo SDK" },
                { id:1, src:"/icons/babel.svg", title:"Babel" },
                { id:2, src:"/icons/github-icon.svg", title:"Github" },
            ]
        },
        primaryImage:[
            {id: 0, src: <Image src={"/images/projects/VaccineWA/HomePage.jpg"} app title="Home Page"  alt="Home Page" />},
            {id: 1, src: <Image src={"/images/projects/VaccineWA/ResultsComponent.jpg"} app title="Results Page" alt="Results Page"/>},
            {id: 2, src: <Image src={"/images/projects/VaccineWA/ProviderComponent.jpg"} app title="Provider Page" alt="Provider Page"/>}
        ],
        //Biggest Obstacle Or Reason for Creation
        problemStatement: "The struggle to find a vaccine",
        problemText:"The vaccine was shipped, ready and people were having difficulties receiving it. The struggle of finding providers that offered the vaccine was further compounded by the strain of booking an appointment. I discovered that the currently available sites were difficult to navigate and may prove inaccessible to the most vulnerable users. It was frustrating, grueling and counter to the vaccination drive to save lives.",        
        //Competitor Image
        secondaryImage: <Image src={"/images/projects/VaccineWA/CovidWA.jpg"} title="CovidWA was the only site available at the time (most popular, as well)" web />,
        //Solution that was formulated
        solutionStatement:"A cross-platform mobile application", 
        solutionText:"An issue of this severity needed simplicity. A simple user interface, instructions and widely disseminated. It needed to be a mobile App. 55-85% of web traffic is accessed via consumer phones. Differentiating between building an iOS and Android app first would waste valuable time. The obvious choice was React-Native. The information needed to be easy to digest and acted upon. Provide users the ability to find a provider, contact a provider and receive the vaccine effortlessly.",        
        //UML Diagram
        umlDiagram: <Image src={""} title="UML Diagram" web/>,
        //Desired Functionality
        functionalPoints:[
            {id:0, title: "Search function that filters ALL the state's providers by zip code to retrieve specified provider info."},
            {id:1, title: "Concisely present the provider contact information and the provide the ability to establish communication through appropriate channels."},
            {id:2, title: "Present pertinent, accurate, and up-to-date information supplied by each exclusive provider."},
            {id:3, title: "Provide indisputable state and federal guidelines on Covid-19 in an efficient, structured manner from trustworthy and assertive authoritative sources."},
        ],        
        tertiaryImage:[
            {id: 0, src: <Image src={"/images/projects/VaccineWA/NavigationDrawer.jpg"} app title="Navigation Drawer"  alt="Navigation Drawer" />},
            {id: 1, src: <Image src={"/images/projects/VaccineWA/VaccinePhasesComponent.jpg"} app title="Vaccine Phases Page" alt="Vaccine Phases Page"/>},
            {id: 2, src: <Image src={"/images/projects/VaccineWA/FaqComponent.jpg"} app title="FAQ Page" alt="FAQ Page"/>}
        ],
        //Design Goal
        designStatement:"Simplicity is actually difficult",
        designText:"Build for what it can be. I designed and conceived of this app as being used by millions in Washington (I know, I know). In order for them to use it, it needed to be intuitive. Can my user understand how to use it instinctively and if not, how do you explicitly present unobtrusive assistance? The instructions and end goal for the app must be clear. It's purpose ascertained at first glance. The most difficult part for me is design. I had to step out of my comfort zone, engineering, and wade into the user interface and experience deep end.",        
        //Font Family Name
        font:[
            {id:0, title:"Scheherazade",weight:"700"},
            {id:1, title:"Open Sans",weight:"400"}
        ],
        colorPalettes:[
            { id:0, title:"#6e6e6e"},
            { id:1, title:"#90CCDE"},
            { id:2, title:"#FFFFFF"},
        ],
        logoImage:<Image src={"/images/projects/VaccineWA/logo.png"} web title="App Logo"  alt="App Logo" />, 
        //Desired UI & UX 
        uiPoints:[
            {id:0, title: "Intuitive navigation and effortless user-flow oriented to fulfilling the user's desire."},
            {id:1, title: "Uncomplicated visual design and information architecture equals effortless moments of interaction. Interface visually 'communicates' with the user."},
            {id:2, title: "A three-step clear and understandable path to the goal. The design ensures that the road is visible, predictable and understandable. Clear visual hierarchy."},
            {id:3, title: "A responsive interface, contrasting palettes, clear fonts, and effective call to actions create visual rhythm and balance. All aligning towards the user's current convenience. Mobile, desktop or tablet."},
            {id:4, title: "Accessibility, accessibility, accessibility. Provide an option of equal access for all."},
        ],
        uiMockups:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/homeMU.png" app title="Home Page Mockup"/>}, 
            {id: 1, src: <Image src="/images/projects/VaccineWA/HomePage.jpg" app title="Home Page"/>}, 
            {id: 2, src: <Image src="/images/projects/VaccineWA/resultsMU.png" app title="Results Page Mockup"/>}, 
            {id: 3, src: <Image src="/images/projects/VaccineWA/ResultsComponent.jpg" app title="Results Page"/>} , 
            {id: 4, src: <Image src="/images/projects/VaccineWA/providerMU.png" app title="Provider Details Mockup"/>},
            {id: 5, src: <Image src="/images/projects/VaccineWA/ProviderComponent.jpg" app title="Provider Details"/>},
            {id: 6, src: <Image src="/images/projects/VaccineWA/vaccinephasesMU.png" app title="Vaccine Phases Mockup"/>},
            {id: 7, src: <Image src="/images/projects/VaccineWA/VaccinePhasesComponent.jpg" app title="Vaccine Phases"/>}
        ],
        //General Victory Statement
        usecaseStatement:"The Covid-19 Vaccine Finder",
        usecaseText: "After three weeks of marathon programming to finish my app, I submitted my project on the final hour for my bootcamp's Graduate with Honors competition and won! In 3 weeks I went from a prototype design on Axure to a fully functioning mobile app. I accomplished my project's objectives and fully engineered my requirements.",        
        quaternaryImage:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/QuestionZoom.jpg" app title="Eligibility CTA"/>},
            {id: 1, src: <Image src="/images/projects/VaccineWA/EligibilityPopOut.jpg" app title="Eligibility PopOut"/>},
            {id: 2, src: <Image src="/images/projects/VaccineWA/EligibilityComponent.jpg" app title="Eligibility Page"/>}
        ],
        //General Statements on Use Cases  
        useStatementPoints:[
            {id:0, title: "Specific and detailed information on the vaccine providers"},
            {id:1, title:  "An aesthetically pleasing visual design"},
            {id:2, title: "Meticulous, detailed information from esteemed sources"},
        ],     
        useTextPoints:[
            {id:0, title: "A simple zip code starts the journey. The search function forages through the Dept. of Health's data set of all the providers in the state that are offering the vaccine. The providers offering vaccines are then displayed alphabetically. Presenting applicable information on the provider; their name, address, telephone, email and web site. An important consideration at the time, was vaccine type and availability. An initial glance was enough to ascertain whether vaccines were in stock at this location, what type of vaccine it was and when this information was last updated. Educating users on alternate options was a high consideration. Displaying zip codes that were within 15 miles of their location and providing that portal to view more providers was mandatory."},
            {id:1, title: "The font choice, color schema and concise instructions accomplished the UI and UX goals outlined from the project's launch. Ensuring that the vulnerable population had no difficulties using the app was obligatory. An incessant question I had was whether or not my user's behavior corresponded to my planned scenario? I proceeded to test my app on my elderly friends and my non-native english speaking friends to verify that the flow was according to plan. The procedural steps they followed to accomplish their goal matched my test case! The key moments of interactions were entirely how I envisioned it. An extra caveat was my intense focus on spacing between elements to ensure that links, images, and text were not jumbled together or interfering with functionality."},
            {id:2, title: "The CDC and the Washington Dept. of Health were my ONLY sources. This was a necessary requirement. The Covid-19 pandemic was, and still is, battling an insidious enemy, misinformation. For my app to assist in this battle I knew that my data could only be sourced from these two reputed organizations. Thankfully their data sets were available for download. Sourcing the providers information from the DOH site was a double-edged sword. I discovered that some data was suffering from inevitable human-errors and had to rectify it by doing research on individual providers. Building the foundations of the app on peer-reviewed, established scientific guidelines was imperative."},
        ],
        useStatementImages:[
            {id: 0, src: <Image src="/images/projects/VaccineWA/providerZoom.jpg" app title="Vaccine Types Available"/>} ,
            {id: 1, src: <Image src="/images/projects/VaccineWA/InstructionsZoom.jpg" app title="Detailed Instructions"/>} ,
            {id: 2, src: <Image src="/images/projects/VaccineWA/ZipcodeRadiusZoom.jpg" app title="Radius Zipcodes Shown"/>} ,
            {id: 3, src: <Image src="/images/projects/VaccineWA/SplashScreen.jpg" app title="Splash Screen"/>} ,
            {id: 4, src: <Image src="/images/projects/VaccineWA/WAPhasesZoom.jpg" app  title="WA Vaccine Equity Details"/>} ,
            {id: 5, src: <Image src="/images/projects/VaccineWA/EligibilityZoom.jpg" app title="Eligibility Questions"/>} ,
            {id: 6, src: <Image src="/images/projects/VaccineWA/FAQZoom.jpg" app title="In-depth Vaccine Info"/>} ,
            {id: 7, src: <Image src="/images/projects/VaccineWA/FAQZoom2.jpg" app title="FAQ Accordion"/>} ,
            {id: 8, src: <Image src="/images/projects/VaccineWA/FAQZoom3.jpg" app title="Sources Revealed"/>}

        ],
        awards:null,       
        //Insight Statements 
        insightStatements:[
            {id:0, title: "Building for others was, and is, software's goal"},
            {id:1, title: "Adapting and pivoting when faced with obstacles is mandatory...Agile?!"},
            {id:2, title:"Your satisfaction for your work is the best reason you can have"},
        ],
        insightTexts:[
            {id:0, title:"With the countless suffering that Covid-19 has caused on my community, state and country; building this app gave me a way to provide help using my expertise. I witnessed the current situation and how my fellow neighbors were having difficulties receiving a vaccine. Building this personal project, under stress, while juggling personal responsibilities, contrary to what I thought it would be, was exhilarating. I set high standards for myself and I went for it. The exhaustion I felt was overshadowed by the exuberance of building something I felt was contributing to alleviating another's difficulties. This project revealed to me that I am interested in social entrepreneurship, building something that helps others, and joining those who contribute to and are fueled by similar endeavors."},
            {id:1, title:"During the development process, I discovered that the current tech stack I had planned on using and diagrammed with, would not suffice for my project. From version inconsistencies to dependency conflicts. Initially it was intimidating, I had to read new documentation and learn as I was building the app. I learned to adapt and update as I programmed. Reading documentation, searching for alternative solutions, and innovating based on feedback from test users.  I was exercising, unbeknownst to me, at the time, Agile  SDLC. The valuable skill of reading documentation, that my instructors raved about, became apparent. I was able to use new technologies, while simultaneously learning them, for my project because of the quick feedback loop I built, resourcefulness, and grit to finish my app with all the features I prototyped."},
            {id:2, title:"When I presented my app to my graduating class. I was selected for Honors. I was so grateful and honored. I was encouraged to publish it and go live on the app stores. I agreed because I saw it as a necessity. I contacted a group of volunteers that built a vaccine finder site similar to what I just created, but desktop. I was prepared to offer the app for free, I just needed to connect their data source. They were receiving live provider updates from the DOH (who just recently closed the data source my app was using). After countless alternating emails, and finally establishing contact. The goal was to potentially provide the front end design to the DOH and her partners directly. Despite our best efforts, it never materialized. This failed to stop me. I moved ahead and decided to publish the app myself and create a backend server that crawled the vaccine providers' site for my data. Then, the unfortunate news of Apple/Google locking out any Covid-19 App from publishing without express approval of Health orgs. was revealed to me. This was disheartening. I felt like I had lost. Although I was sad, I remembered how much fun I had building it, prototyping it, and conceiving it. Having the courage to cross that shadow and make it real. I did not witness my project being used as I wished, but I knew I loved the process, its highs and lows. My satisfaction with my product and my users (even potential users) is what thrilled me. And, hey, at least my friends set appointments.😁"},
        ],
        //Videos, Publications etc.
        extraLinks: null,
        designConcept:"Nasir Abdirahman",
        development:"Nasir Abdirahman",
    },


]

  
export default PROJECTS;
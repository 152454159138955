import React from 'react';
import { useHref,useLinkClickHandler } from "react-router-dom"; 
import { styled } from '@mui/system';


/*
Link Component -- {Link} from React-Router -- Internal Nav
---------------------------------------------------------
*/


//Link(Anchor) Styling
const StyledLink = styled('a')(({ theme }) => ({
    color:theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
        color:  '#17D1FA',
        textDecoration: 'none',
    }
}));



const Link = React.forwardRef(( {onClick, replace = false, state, target, to, ...rest},  ref) => {
    let href = useHref(to);
    let handleClick = useLinkClickHandler(to, {replace, state, target} );

    return (
        <StyledLink
            {...rest}
            href={href}
            onClick={event => {
                onClick?.(event);
                if (!event.defaultPrevented) {
                    handleClick(event);
                }
            }}
            ref={ref}
            target={target}
        />
    );
    
});


export default Link;
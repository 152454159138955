import {useState, useCallback} from 'react';
import {useSpring} from '@react-spring/web';




/* useHoverSpring Animation Hook -- HOVER EFFECT */
function useHoverSpring ({

    /*
    Default Values Passed in at their natural state
    Allows us to only specify values we want to change
    */
    x = 0,
    y = 0,
    //rotation = 0,
    //scale = 1,
    fromOpacity = null,
    toOpacity = null,
    fromTransform = null,
    toTransform = null,
    reverse = false,
    reset = false,
    fromColor = null,
    toColor = null,
    fromBgColor = null,
    toBgColor = null,

    springConfig = {
        tension: 150,
        friction: 10,
        duration: 250 //Passed in Prop
    },
    }) {

    //State initialized
    const [value, setValue] = useState(false);

    //Style Applied
    const style = useSpring({
        x : (value ? x : 0),
        y : (value ? y : 0),
        reverse : reverse,
        reset : reset,
        opacity: (value ? toOpacity : fromOpacity),
        background : (value ? toBgColor : fromBgColor),//Color props optional
        color : (value ? toColor : fromColor),//Color props optional
        transform : (value ? toTransform : fromTransform),
        config: springConfig,
    })
    
   
    //Trigger Events for spring
    const onMouseEnter = useCallback(() => setValue(true), []);
    const onMouseLeave = useCallback(() => setValue(false), []);
    

    return (
        [style, onMouseEnter,onMouseLeave]
    )
}


export default useHoverSpring;
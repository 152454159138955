/*
Palettes - Dark/Light Mode
--------------------------
*/


const palettes = {

  //Palette values for Light mode
  light: {
    mode:'light',
    primary: {
      main: '#000000',//Black
      secondary: '#ffffff',//White
      tertiary: '#17D1FA'//Sky Blue
    },
    //divider: amber[200],
    text: {
      primary: '#000000',//Black
      secondary: '#ffffff',//White
      link: '#023DFD',//Blue
    },
    background: {
      default: "#ffffff",//ROOT DEFAULT PASSED TO CssBaseline
      secondary:'#ff0000'//Black
    },
  },

  //Palette values for Dark mode
  dark: {
    mode:'dark',
    primary: {
      main: '#ffffff',//White
      secondary: '#000000',//Black
      tertiary: '#ff0000'//Red
    },
    //divider: deepOrange[700],
    background: {
      default: '#000000',//ROOT DEFAULT PASSED TO CssBaseline
      secondary:'#ff0000'//White
      //paper: ,
    },
    text: {
      primary: '#ffffff',//White
      secondary: '#000000',//Black
      link: '#17D1FA',//Sky Blue

    },
  },

    
}

export default palettes;
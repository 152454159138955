import React,{useState,useEffect} from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { Avatar, useMediaQuery } from '@mui/material';
import { Paragraph } from '../Shared/TypographyComponent';
import { animated } from '@react-spring/web';
import useHoverSpring from '../../hooks/useHoverSpring';
import {AnimatedGrid}  from '../../layouts/AnimatedContainers';
import useHover from '../../hooks/useHover';


/*
Email Component 
---------------
*/



//Custom Styling For Button
const StyledButton = styled(Button)((props) => ({
    backgroundColor:'#17D1FA',
    borderRadius:'20px',
    borderStyle: 'solid',
    borderWidth: '1.5px',
}));



const EmailButton = () => {


    //Controls Hover effect
    const [hover, isHovered] = useHover()
    
    //Controls State of Copied Button
    const [showCopied, setShowCopied] = useState(false)

    //Animated Components
    const AnimatedAvatar = animated(Avatar)
    const AnimatedButton = animated(StyledButton)

    //Emoji Icons
    const happyFace = "/icons/Happy.png"
    const heartEyes = "/icons/HeartEyes.png"
    const thumbsUp = "/icons/ThumbsUp.png"

    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    //Click Event & Clipboard Copy function
    const onClickEvent =  () => { setShowCopied(true); navigator.clipboard.writeText("nasir.a.abdirahman@outlook.com") } 
    //Windows Version -- navigator.clipboardData.setData("Text","nasir.a.abdirahman@outlook.com")

    //Effect Resets showCopied Button State
    useEffect(() => {
        //Only act when showCopied is SetShowCopied
        if(!showCopied){
            return;
        }
        //Timeout resets State
        const timeoutId = window.setTimeout(() => {
            setShowCopied(false);

        }, 2500);
        //Incase comp. unmounts,cancel timeout, to prevent memory leak
        return () => {
            window.clearTimeout(timeoutId);
        };

    }, [showCopied])


    /*Hover Spring Hook */
    const [style, onMouseEnter, onMouseLeave] = useHoverSpring({
        fromBgColor: '#17D1FA',
        toBgColor: (isHovered && !showCopied && !Mobile)  ? '#ffffff' //Before onClick Event
            : showCopied ? "#ff0000" //After Click Event
            : '#17D1FA'
        ,
        springConfig: { 
            duration: 10
        },
    })




    return (
        <>
            <AnimatedGrid container item ref={hover}>

                {!showCopied ? 

                    <AnimatedButton 
                        style={style}
                        onMouseEnter={onMouseEnter} 
                        onMouseLeave={onMouseLeave}
                        onClick={onClickEvent}
                        variant="contained" size="50px" 
                        startIcon={<AnimatedAvatar src={ isHovered && !Mobile ? heartEyes : happyFace }/> } 
                        
                        aria-label="Copy The Developer Email to Clipboard"
                        
                    >
                        <Paragraph  sx={{color: '#000000'}}>{isHovered && !Mobile ? "Copy Email" : "Email Me"}</Paragraph>
                    </AnimatedButton>

                :

                    <AnimatedButton 
                        style={style} //--ADD OWN STYLING
                        variant="contained" size="50px" 
                        startIcon={<Avatar src={thumbsUp} /> } 
                        aria-label="Copied Email"
                    >
            
                        <Paragraph sx={{color: '#ffffff'}}>Copied!</Paragraph>
                    </AnimatedButton>
                
                }

            </AnimatedGrid>         
        </>
    )
}

export default EmailButton;


/* 
    <StyledButton ref={hover} 
        onClick={onClickEvent}
        variant="contained" 
        size="50px" 
        startIcon={
            showCopied ? 
            <Avatar src={thumbsUp} /> 
            : 
            <Avatar src={setHover ? heartEyes : happyFace} /> 
        } 
        aria-label={showCopied ? "Copied Email" : "Copy The Developer's Email to Clipboard"}
        
    >
        
    {showCopied ? 
        <Paragraph>Email Copied</Paragraph> 
        : 
        <Paragraph>{setHover ? "Copy Email" : "Email Me"}</Paragraph>
    }
    
    </StyledButton>
*/
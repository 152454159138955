import { Grid } from "@mui/material";
import { animated } from '@react-spring/web';



/* Animated Div -- wrapper for animated spring components */
const AnimatedDiv =  animated.div

/* Animated Grid -- wrapper for animated spring components */
const AnimatedGrid = animated(Grid)


export { AnimatedDiv, AnimatedGrid }
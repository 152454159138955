import React from 'react';
import {Title, SubTitle, Paragraph} from '../Shared/TypographyComponent';
import { Grid, Stack, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';


/*
Project Color Palette Component
-------------------------------
*/


//Custom Styling For Circle Image-(Cant pass theme b/c not native comp.)
const StyledCircle = styled('img')((props) => ({
    backgroundColor: props.color,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius:'20px',
}))
    

//Circle Image
const PaletteCircle = ({children, alt, src, color}) => {
    return (
        <>
            <StyledCircle  src={src} alt={alt} color={color.title}
                sx={{ 
                    height: { xs: '6em', md: '6em' },
                    width: { xs: '6em', md: '6em' },
                    borderColor: 'primary.main' 
                }} 
            >  
                {children}
            </StyledCircle>
        </>
    )
};



//Color Palette Images Mapped
const PaletteContainer = ({project}) => {
    return (
        <>
            {project.colorPalettes.map((color) => (
                <Stack key={color.id} alignItems="center" pb={5} alt={`Circle filled with the colors of ${color.title}` } >
                    <PaletteCircle color={color}  /> 
                    <Paragraph>{color.title}</Paragraph>
                </Stack>     
            ))}
        </>
    )
}


//Project Palette Container
function ProjectPalette ({project}) {

    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

    return(
        <>
            <Grid container sx={{pb:12}} justifyContent="center">
                <Title>Color Palettes</Title>
            </Grid>
            
       
            <Grid container justifyContent={Mobile ? "space-around" : "space-between"}>
                
                <Grid item sm={4}>
                    <Stack spacing={18} pt={4.5} alignItems="center" pr={3}>
                        <SubTitle>Primary</SubTitle> 
                        <SubTitle>Secondary</SubTitle> 
                        <SubTitle>Tertiary</SubTitle> 
                    </Stack>
                </Grid>
            
                <Grid item sm={4}>
                    <PaletteContainer project={project}/>   
                </Grid>

            </Grid>              
        </>
    )
}


export default ProjectPalette;
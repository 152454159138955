import React from 'react';
import { Paragraph } from '../Shared/TypographyComponent';
import { Grid, useMediaQuery } from '@mui/material';



/*
Project Design Mockups Component
--------------------------------
*/


const ProjectMockups = ({project}) => {
    //Mobile MediaQuery
    const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))


    if(project.uiMockups) {
        return (
            
            <>
                <Grid container justifyContent={Mobile? "space-evenly":"space-between"}>

                    {project.uiMockups.map((image) => (
                        <Grid item key={image.id} sx={{pt:5}}>
                            {image.src} 
                        </Grid>
                    ))}
                    
                </Grid>
            </>     

        )
    } 
    else { //NO MOCKUPS CREATED
        return(
            <Paragraph sx={{pt:5}} align="center">Their were no mockups for this project. This may be for a variety of reasons. A) I was iterating quickly and after designing the landing page, 
                all other pages followed suite and matched the design or B) I was in the 'zone' and had the entire design in my head already.
            </Paragraph>
        )
    }

}

export default ProjectMockups;
import {useSpring} from '@react-spring/web';



/* UseBounce Spring Animation Hook  -- PERPETUAL BOUNCE  */
function useBounce ({

    //Default Values Passed in at their natural state
    //Allows us to only specify values we want to change
    x = 0,
    y = 0,
    reverse = false,
    fromColor = false,
    toColor = false,
    //rotation = 0,
    //scale = 1,
    springConfig = {
        tension: 300,
        friction: 10,
        duration : 750
    },

    }) {

    //Styles Applied - Props passed in
    const style = useSpring({
        loop: {reverse : reverse},
        from: { 
            x : 0, 
            y : 0, 
            color : fromColor //Color props optional
        },
        to: { 
            x : x,
            y : y,
            color : toColor //Color props optional
        },
        config:springConfig,
    })


    return [style]
}

export default useBounce;
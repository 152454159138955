import React from 'react'
import { styled } from '@mui/system';


/*
Bullet List Component
---------------------
*/



//Styling
const StyledBullet = styled('li')(({ theme }) => ({
    listStyle:'none',
    paddingBottom: '20px',
    paddingRight:'5px',
    //Custom Bullet Point Created using CSS
    '&:before': {
        content: "'\\2022'",//Unicode for Bullet
        fontSize:'40px', //Size of Bullet
        //color:theme.palette.primary.tertiary,//REMOVED, CONTROLLED BY PARAGRAPH
        display:'inline-block',
        width:'0.5em',
    },

}));


//Bullet List Component
const  BulletList = ({children}) => {
    return (  
        <StyledBullet>
            {children} {/*TEXT DEFAULTS TO PARAGRAPH */}
        </StyledBullet>      
    )
};


export default BulletList;
import React,{ useContext } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { useTheme} from '@mui/material/styles';
import {DarkModeContext} from './darkMode'
import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

/*
Theme Toggler Component
-----------------------
*/



//Custom Styling For Button
const StyledButton = styled(Button)((props) => ({
//  backgroundColor:'background.secondary',
  borderRadius:'20px',
  borderStyle: 'solid',
  borderWidth: '1.5px',
  borderColor: 'primary',
  height:35,
}));


function ThemeToggler() {
  //Theme Prop
  const theme = useTheme();
  //Context Prop
  const darkMode = useContext(DarkModeContext);
  //Click Event Handler
  const onClickEvent =  () => { darkMode.toggleDarkMode() }
  //Mobile MediaQuery
  const Mobile = useMediaQuery(theme => theme.breakpoints.only('xs'))

  return (

    <Grid onClick={onClickEvent}>

      {Mobile ? 
          
        /* Toggler for Mobile */
        <IconButton  color="inherit" sx={{ borderColor:'primary.main',borderStyle:'solid', borderRadius:'20px', borderWidth:'1px', padding:'4px'}} alt="Change the color theme from Dark To Light">
          {theme.palette.mode === 'dark' ? <LightModeIcon sx={{color: 'primary.main'}}/>: <DarkModeIcon sx={{color: 'primary.main'}}/>}
        </IconButton>
      

      :

        /* Toggler for Desktop */
        theme.palette.mode === 'dark' ? 
        <StyledButton aria-label="Switch between dark and light mode" startIcon={<LightModeIcon sx={{color: 'primary.main', mr:2.5}}/>} />//#ffff00 YELLOW
        :
        <StyledButton aria-label="Switch between dark and light mode" endIcon={<DarkModeIcon sx={{color: 'primary.main', ml:2.5}}/>} /> //#17D1FA Sky Blue
      }

    </Grid>
    
  );
}


export default ThemeToggler;
import React, {useEffect} from 'react';
import { useNavigationType, useNavigate } from 'react-router-dom';


/*
Scroll To Top On Page Load
--------------------------
*/


const ScrollToTop = (props) => {

    //Navigation Hooks
    let navigate = useNavigate();

    //Tracks Back Button and nullifies scroll2top
    const NavigationType = useNavigationType()
    
    
    useEffect(() =>  {

        if(NavigationType !== "POP" || navigate === (-1) ) {
            window.scrollTo(0, 0);
        }
    }, [NavigationType,navigate]);
  
    return <>{props.children}</>
};
  
export default ScrollToTop;
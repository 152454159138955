import { createTheme } from '@mui/material/styles';


/*
Font Styling & Breakpoints
--------------------------
*/


let theme = createTheme();

const typography = {

    /*Header Breakpoint*/
    h1:{
        fontFamily:'Montserrat Alternates',
        fontWeight:'700',
        [theme.breakpoints.up('xs')]: {
        fontSize: '60px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '75px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '103px',
        },
    },

    /*SubHeader Breakpoint*/
    h2: {
        fontFamily:'Montserrat',
        fontWeight:'600',
        [theme.breakpoints.up('xs')]: {
        fontSize: '32px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '34px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '60px',
        },
    },

    /*Title Breakpoint*/
    h3: {
        fontFamily:'Montserrat',
        fontWeight:'600',
        [theme.breakpoints.up('xs')]: {
        fontSize: '25px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '28px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '35px',
        },
    },

    /*SubTitle Breakpoint*/ 
    h4: {
        fontFamily:'Montserrat',
        fontWeight:'600',
        [theme.breakpoints.up('xs')]: {
        fontSize: '18.5px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '24px',
        },
    },

    /*Paragraph Breakpoint*/
    body1: {
        fontFamily:'Montserrat',
        fontWeight:'500',
        lineHeight:'1.6em',
        [theme.breakpoints.up('xs')]: {
        fontSize: '16px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '16px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '18px',
        },
    },

    /*Caption Breakpoint*/
    body2: {
        fontFamily:'Montserrat',
        fontWeight:'500',
        lineHeight:'1.6em',
        [theme.breakpoints.up('xs')]: {
        fontSize: '11px',
        },
        [theme.breakpoints.up('sm')]: {
        fontSize: '13px',
        },
        [theme.breakpoints.up('md')]: {
        fontSize: '15px',
        }
    }
    
}

export default typography;
import React from 'react';
import { Paragraph } from '../Shared/TypographyComponent';
import ExternalLink from '../Shared/ExternalLinkComponent';
import { Stack } from '@mui/material';



/*
Contact Information Component
-----------------------------
*/


const Contact = ({links}) => {

    return ( 
        <>
            {links.map((link) => (
            
                <Stack key={link.id} alignItems="center" >
                    <ExternalLink href={link.url} target="_blank">
                        {link.icon}
                    </ExternalLink>
                    <Paragraph>{link.title}</Paragraph>
                </Stack>                 
            
            ))}  
        </>         
    )
}
                                    

export default Contact;
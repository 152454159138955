import {useState, /*useEffect,*/useCallback, useRef } from 'react';


/*
UseHover React Hook
-------------------
*/


function useHover() {

  //Set Initial State
  const [value, setValue] = useState(false);

  //Keep track of the last node passed to callbackRef
  //so we can remove its event listeners.
  const ref = useRef(null)

  
  //Mouse Events
  const handleMouseEnter = useCallback(() => setValue(true), [])
  const handleMouseLeave = useCallback(() => setValue(false), [])


  //Event Listeners
  //Using Callback ref as explained at https://gist.github.com/gragland/a32d08580b7e0604ff02cb069826ca2f
  const callbackRef =  useCallback(
    (node) => {
      if(ref.current) {
        ref.current.removeEventListener("mouseenter", handleMouseEnter);
        ref.current.removeEventListener("mouseleave", handleMouseLeave);
      }

      ref.current = node;

      if(ref.current) {
        ref.current.addEventListener("mouseenter", handleMouseEnter);
        ref.current.addEventListener("mouseleave", handleMouseLeave);
      }
    },
    [handleMouseEnter, handleMouseLeave]
  )


  return [callbackRef, value];
   
  
}


export default useHover;


/* Usage 
  const [hoverRef, isHovered] = useHover();
   <button ref={hoverRef} style={{ background: "#ddd", padding: 50 }}>
    <img src="http://placekitten.com/200/300" alt="kitten" />
    </button>
*/


/*Mouse Events-wrong version
    const handleMouseEnter = () => setValue(true);
    const handleMouseLeave = () => setValue(false);*/


    //Adding & Removing Event Listeners
    /*useEffect(
        () => {
          const node = ref.current;
          
          if (node) {
            node.addEventListener("mouseenter", handleMouseEnter);
            node.addEventListener("mouseleave", handleMouseLeave);
            return () => {
              node.removeEventListener("mouseenter", handleMouseEnter);
              node.removeEventListener("mouseleave", handleMouseLeave);
            };
          }
        },
        //[ref.current]= from site--THROWS ERRORS // Recall only if ref changes
        []
    );
    return[ref, value]*/
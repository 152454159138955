import React from 'react';
import { Grid } from '@mui/material';
import Thumbnail from '../Shared/ThumbnailComponent';
import { Paragraph } from '../Shared/TypographyComponent';

/*
Areas Of Expertise & Skills Component
-------------------------------------
*/


 const AreasOfExpertise = ({skills}) => {

    return ( 
        <> 

            {/* Languages */}
            <Grid container justifyContent="center">
                <Paragraph>Languages: </Paragraph> 
           
                <Grid container item justifyContent="space-evenly"  sm={8}>
                    {skills.slice(0,5).map((skill) => (
                        <Grid item key={skill.id} sx={{p:1}} >
                            <Thumbnail src={skill.src} title={skill.title}/> 
                        </Grid>
                    ))} 
                </Grid>
            </Grid>
           

            {/* Front-End */}
            <Grid container justifyContent="center" sx={{pt:7}}>
                <Paragraph>Front-End: </Paragraph> 

                <Grid container item justifyContent="space-evenly" sm={8} >
                    {skills.slice(5,18).map((skill) => (
                        <Grid item key={skill.id} sx={{p:1}} >
                            <Thumbnail src={skill.src} title={skill.title}/> 
                        </Grid>
                    ))} 
                </Grid>
            </Grid> 


            {/* Back-End */}
            <Grid container justifyContent="center" sx={{pt:7}}>
                <Paragraph>Back-End: </Paragraph> 

                <Grid container item justifyContent="space-evenly" sm={8}>
                    {skills.slice(18,23).map((skill) => (
                        <Grid item key={skill.id} sx={{p:1}} >
                            <Thumbnail src={skill.src} title={skill.title}/> 
                        </Grid>
                    ))} 
                </Grid>
            </Grid>


            {/* Tools */}
            <Grid container justifyContent="center" sx={{pt:7}}>
                <Paragraph>Tools & Deployment: </Paragraph> 

                <Grid container item justifyContent="space-evenly" sm={8}>
                    {skills.slice(23,40).map((skill) => (
                        <Grid item key={skill.id} sx={{p:1}} >
                            <Thumbnail src={skill.src} title={skill.title}/> 
                        </Grid>
                    ))} 
                </Grid>
            </Grid>          
        </>         
    )
}
 


export default AreasOfExpertise;
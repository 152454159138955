import React from 'react';
import { Helmet } from 'react-helmet-async';


//Document Head Manager Component
const HelmetTag = ({title,description, href}) => {
    return (
        
        <Helmet>
            <title>{title || "Nasir Abdirahman - Software Engineer"}</title>{/*Added OR statement b/c react helmet takes child helmet as precedence */}
            <meta name="description" content={description || "Nasir Abdirahman is a software engineer that designs and builds web applications, PWAs and cross-platform mobile apps."} />
            <link rel="canonical" href={href || "https://www.nasirabdirahman.com"} />
        </Helmet>

    )
}

export default HelmetTag;